import React from 'react';
import { useFormContext } from 'react-hook-form';
import type { Venue } from '../../../../../types/venue';
import { Input } from '../../../../../components/ui/Input';
import { Select } from '../../../../../components/ui/Select';
import { Typography } from '../../../../../components/ui/Typography';
import { Textarea } from '../../../../../components/ui/Textarea';

export function SpaceDetails() {
  const { register, formState: { errors } } = useFormContext<Venue>();

  return (
    <div className="space-y-6">
      <Typography variant="h3" className="text-filliminal-yellow mb-4">
        Space Specifications
      </Typography>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <div>
          <label className="block text-filliminal-yellow mb-2">Total Square Footage</label>
          <Input
            type="number"
            {...register('spaceDetails.squareFootage', { 
              required: 'Square footage is required',
              min: { value: 1, message: 'Must be greater than 0' }
            })}
            placeholder="Enter square footage"
            error={errors.spaceDetails?.squareFootage?.message}
          />
        </div>

        <div>
          <label className="block text-filliminal-yellow mb-2">Maximum Capacity</label>
          <Input
            type="number"
            {...register('spaceDetails.maxCapacity', { 
              required: 'Maximum capacity is required',
              min: { value: 1, message: 'Must be greater than 0' }
            })}
            placeholder="Enter maximum capacity"
            error={errors.spaceDetails?.maxCapacity?.message}
          />
        </div>

        <div>
          <label className="block text-filliminal-yellow mb-2">Floor Level</label>
          <Input
            {...register('spaceDetails.floorLevel')}
            placeholder="e.g., Ground Floor, Second Floor"
          />
        </div>

        <div>
          <label className="block text-filliminal-yellow mb-2">Room Height</label>
          <Input
            type="number"
            {...register('spaceDetails.roomHeight')}
            placeholder="Enter ceiling height in feet"
          />
        </div>
      </div>

      <div className="space-y-4">
        <Typography variant="h4" className="text-filliminal-yellow mb-4">
          Layout Options
        </Typography>
        
        <div className="grid grid-cols-2 md:grid-cols-3 gap-4">
          {[
            'Theater',
            'Classroom',
            'Banquet',
            'Reception',
            'U-Shape',
            'Boardroom',
            'Hollow Square',
            'Custom'
          ].map(layout => (
            <label key={layout} className="flex items-center space-x-2">
              <input
                type="checkbox"
                {...register('spaceDetails.layouts')}
                value={layout.toLowerCase()}
                className="form-checkbox text-filliminal-yellow rounded border-filliminal-yellow/20 bg-transparent"
              />
              <span className="text-filliminal-yellow">{layout}</span>
            </label>
          ))}
        </div>
      </div>

      <div>
        <label className="block text-filliminal-yellow mb-2">Additional Space Features</label>
        <Textarea
          {...register('spaceDetails.additionalFeatures')}
          placeholder="Describe any additional features or specifications of the space..."
          rows={4}
        />
      </div>

      <div className="space-y-4">
        <Typography variant="h4" className="text-filliminal-yellow mb-4">
          Equipment and Infrastructure
        </Typography>
        
        <div className="grid grid-cols-2 md:grid-cols-3 gap-4">
          {[
            'Power Outlets',
            'HVAC',
            'Natural Light',
            'Blackout Capability',
            'Loading Dock',
            'Elevator Access',
            'Stage',
            'Green Room'
          ].map(feature => (
            <label key={feature} className="flex items-center space-x-2">
              <input
                type="checkbox"
                {...register('spaceDetails.infrastructure')}
                value={feature.toLowerCase().replace(/\s+/g, '-')}
                className="form-checkbox text-filliminal-yellow rounded border-filliminal-yellow/20 bg-transparent"
              />
              <span className="text-filliminal-yellow">{feature}</span>
            </label>
          ))}
        </div>
      </div>
    </div>
  );
}
