import React from 'react';

interface NavSectionProps {
  title: string;
  children: React.ReactNode;
}

export function NavSection({ title, children }: NavSectionProps) {
  return (
    <div>
      <h3 className="px-4 text-sm font-medium text-filliminal-yellow/50 mb-4">{title}</h3>
      <div className="space-y-1">{children}</div>
    </div>
  );
}