import React from 'react';
import { useDashboard } from '../../lib/hooks/useDashboard';
import { Card } from '../../components/ui/Card';
import { Typography } from '../../components/ui/Typography';

export function DashboardHome() {
  const { data, isLoading, error } = useDashboard();

  if (isLoading) {
    return <div>Loading dashboard data...</div>;
  }

  if (error) {
    return <div>Error loading dashboard: {error.message}</div>;
  }

  return (
    <div className="space-y-6">
      <Typography variant="h1">Welcome Back!</Typography>
      
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        <Card className="p-6">
          <Typography variant="h3">Recent Activity</Typography>
          {/* Add recent activity content */}
        </Card>

        <Card className="p-6">
          <Typography variant="h3">Quick Actions</Typography>
          {/* Add quick actions content */}
        </Card>

        <Card className="p-6">
          <Typography variant="h3">Statistics</Typography>
          {/* Add statistics content */}
        </Card>
      </div>
    </div>
  );
};
