import React, { useState } from 'react';
import { Star, Edit, Clock, MessageSquare } from 'lucide-react';
import { Typography } from '../../../../components/ui/Typography';
import { Card } from '../../../../components/ui/Card';
import { Button } from '../../../../components/ui/Button';

type ReviewStatus = 'pending' | 'published' | 'drafts' | 'responses';

export function Reviews() {
  const [activeStatus, setActiveStatus] = useState<ReviewStatus>('pending');

  const statusTabs = [
    { id: 'pending', label: 'Pending Reviews', icon: Clock },
    { id: 'published', label: 'Published', icon: Star },
    { id: 'drafts', label: 'Review Drafts', icon: Edit },
    { id: 'responses', label: 'Response Management', icon: MessageSquare },
  ];

  return (
    <div className="space-y-6">
      <Typography variant="h2" className="text-filliminal-yellow">Reviews</Typography>

      <div className="flex space-x-4 overflow-x-auto pb-2">
        {statusTabs.map(({ id, label, icon: Icon }) => (
          <Button
            key={id}
            variant={activeStatus === id ? 'primary' : 'secondary'}
            className="flex items-center gap-2"
            onClick={() => setActiveStatus(id as ReviewStatus)}
          >
            <Icon className="w-4 h-4" />
            {label}
          </Button>
        ))}
      </div>

      <div className="grid gap-4">
        {[1, 2, 3].map((review) => (
          <Card key={review} className="bg-filliminal-dark-lighter border border-filliminal-yellow/20">
            <Card.Body>
              <div className="flex items-start justify-between">
                <div className="flex items-start gap-4">
                  <div className="p-3 bg-filliminal-yellow/10 rounded-lg">
                    <Star className="w-6 h-6 text-filliminal-yellow" />
                  </div>
                  <div>
                    <Typography variant="h4">Downtown Studio</Typography>
                    <div className="flex items-center gap-2 mt-1">
                      <div className="flex">
                        {[1, 2, 3, 4, 5].map((star) => (
                          <Star
                            key={star}
                            className="w-4 h-4 text-filliminal-yellow"
                            fill="currentColor"
                          />
                        ))}
                      </div>
                      <Typography variant="small" className="text-filliminal-yellow/70">
                        March 15, 2024
                      </Typography>
                    </div>
                    <Typography variant="body" className="mt-2 text-filliminal-yellow/90">
                      Amazing space! Perfect for our team offsite meeting. The host was very accommodating.
                    </Typography>
                  </div>
                </div>
                <Button variant="secondary" size="sm">
                  {activeStatus === 'pending' ? 'Write Review' : 'View Details'}
                </Button>
              </div>
            </Card.Body>
          </Card>
        ))}
      </div>
    </div>
  );
}