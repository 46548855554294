import { 
  GoogleAuthProvider, 
  FacebookAuthProvider, 
  signInWithPopup,
  signInWithRedirect,
  linkWithPopup,
  unlink,
  getAdditionalUserInfo,
  OAuthProvider,
  UserCredential
} from 'firebase/auth';
import { auth, db } from '../firebase';
import { doc, getDoc, setDoc, updateDoc } from 'firebase/firestore';
import { tokenService } from './tokenService';

// Use Web Crypto API directly
const generateNonce = (length: number) => {
  const array = new Uint8Array(length);
  window.crypto.getRandomValues(array);
  return Array.from(array, byte => byte.toString(16).padStart(2, '0')).join('');
};

export type SocialProvider = 'google' | 'facebook';

interface SocialProfile {
  id: string;
  email: string;
  name?: string;
  picture?: string;
  providerId: string;
}

class SocialAuthService {
  private providers = {
    google: new GoogleAuthProvider(),
    facebook: new FacebookAuthProvider()
  };

  constructor() {
    // Add scopes for each provider
    this.providers.google.addScope('https://www.googleapis.com/auth/userinfo.profile');
    this.providers.google.addScope('email');
    
    // Configure Facebook provider
    this.providers.facebook.addScope('public_profile');
    this.providers.facebook.addScope('email');
    
    // Set custom parameters for Facebook
    this.providers.facebook.setCustomParameters({
      'display': 'popup'
    });
  }

  private getProvider(providerName: SocialProvider): OAuthProvider {
    const provider = this.providers[providerName];
    if (!provider) {
      throw new Error(`Unsupported provider: ${providerName}`);
    }
    return provider;
  }

  async signIn(providerName: SocialProvider): Promise<UserCredential | null> {
    try {
      const provider = this.getProvider(providerName);
      
      // Add state parameter for CSRF protection
      const state = generateNonce(16);
      sessionStorage.setItem('socialAuthState', state);
      
      // Configure provider parameters
      const customParams: Record<string, string> = {
        state,
        prompt: 'select_account'
      };
      
      if (providerName === 'facebook') {
        customParams.display = 'popup';
      }
      
      provider.setCustomParameters(customParams);

      // Use try-catch specifically for popup blocked errors
      let result;
      try {
        result = await signInWithPopup(auth, provider);
      } catch (error: any) {
        console.error('Popup error:', error);
        if (error.code === 'auth/popup-blocked' || error.code === 'auth/popup-closed-by-user') {
          console.log('Popup was blocked or closed, falling back to redirect...');
          await signInWithRedirect(auth, provider);
          return null; // The redirect will handle the sign-in
        }
        throw error;
      }
      
      // Verify state parameter
      const storedState = sessionStorage.getItem('socialAuthState');
      sessionStorage.removeItem('socialAuthState');
      
      if (!storedState || storedState !== state) {
        throw new Error('Invalid state parameter');
      }

      // Get additional user info
      const additionalInfo = getAdditionalUserInfo(result);
      if (additionalInfo?.isNewUser) {
        await this.createSocialUserProfile(result, providerName);
      } else {
        await this.updateSocialUserProfile(result, providerName);
      }

      // Get fresh token
      await tokenService.getToken(true);

      return result;
    } catch (error: any) {
      console.error(`${providerName} sign in failed:`, error);
      
      if (error.code === 'auth/account-exists-with-different-credential') {
        const email = error.customData?.email;
        if (email) {
          const methods = await auth.fetchSignInMethodsForEmail(email);
          throw new Error(`Account exists with ${methods[0]}. Please sign in with that provider first.`);
        }
      }
      
      throw error;
    }
  }

  async linkAccount(providerName: SocialProvider): Promise<UserCredential> {
    const currentUser = auth.currentUser;
    if (!currentUser) {
      throw new Error('No user signed in');
    }

    try {
      const provider = this.getProvider(providerName);
      const result = await linkWithPopup(currentUser, provider);
      await this.updateSocialUserProfile(result, providerName);
      return result;
    } catch (error: any) {
      console.error(`Failed to link ${providerName} account:`, error);
      throw error;
    }
  }

  async unlinkAccount(providerName: SocialProvider): Promise<void> {
    const currentUser = auth.currentUser;
    if (!currentUser) {
      throw new Error('No user signed in');
    }

    try {
      await unlink(currentUser, this.providers[providerName].providerId);
      
      // Update user profile to remove provider data
      const userRef = doc(db, 'users', currentUser.uid);
      await updateDoc(userRef, {
        [`socialProfiles.${providerName}`]: null
      });
    } catch (error) {
      console.error(`Failed to unlink ${providerName} account:`, error);
      throw error;
    }
  }

  private async createSocialUserProfile(
    credential: UserCredential,
    provider: SocialProvider
  ): Promise<void> {
    const { user } = credential;
    const additionalInfo = getAdditionalUserInfo(credential);
    
    const socialProfile: SocialProfile = {
      id: additionalInfo?.profile?.id || user.uid,
      email: user.email || '',
      name: user.displayName || '',
      picture: user.photoURL || '',
      providerId: this.providers[provider].providerId
    };

    const userRef = doc(db, 'users', user.uid);
    const userData = {
      uid: user.uid,
      email: user.email,
      displayName: user.displayName,
      photoURL: user.photoURL,
      createdAt: new Date().toISOString(),
      lastLoginAt: new Date().toISOString(),
      socialProfiles: {
        [provider]: socialProfile
      },
      providers: [provider]
    };

    await setDoc(userRef, userData);
  }

  private async updateSocialUserProfile(
    credential: UserCredential,
    provider: SocialProvider
  ): Promise<void> {
    const { user } = credential;
    const additionalInfo = getAdditionalUserInfo(credential);
    
    const userRef = doc(db, 'users', user.uid);
    const userDoc = await getDoc(userRef);
    
    if (!userDoc.exists()) {
      await this.createSocialUserProfile(credential, provider);
      return;
    }

    const socialProfile: SocialProfile = {
      id: additionalInfo?.profile?.id || user.uid,
      email: user.email || '',
      name: user.displayName || '',
      picture: user.photoURL || '',
      providerId: this.providers[provider].providerId
    };

    await updateDoc(userRef, {
      [`socialProfiles.${provider}`]: socialProfile,
      lastLoginAt: new Date().toISOString(),
      providers: [...new Set([...userDoc.data().providers || [], provider])]
    });
  }
}

export const socialAuthService = new SocialAuthService();
