import React from 'react';
import { User } from 'lucide-react';
import { useAuthStore } from '../../../../store/authStore';

export function UserProfile() {
  const { user } = useAuthStore();

  return (
    <div className="mt-auto pt-4 border-t border-filliminal-yellow/20">
      <div className="flex items-center gap-3 px-4 py-2">
        <div className="w-10 h-10 rounded-full bg-filliminal-yellow/10 flex items-center justify-center">
          <User className="w-5 h-5 text-filliminal-yellow" />
        </div>
        <div>
          <div className="text-filliminal-yellow font-medium">{user?.displayName}</div>
          <div className="text-sm text-filliminal-yellow/70">{user?.email}</div>
        </div>
      </div>
    </div>
  );
}