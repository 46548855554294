import React, { useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useNavigate } from 'react-router-dom';
import type { Venue } from '../../../../types/venue';
import { BasicDetails } from './steps/BasicDetails';
import { LocationInfo } from './steps/LocationInfo';
import { SpaceDetails } from './steps/SpaceDetails';
import { MediaUpload } from './steps/MediaUpload';
import { PricingAvailability } from './steps/PricingAvailability';
import { AmenitiesRules } from './steps/AmenitiesRules';
import { Button } from '../../../../components/ui/Button';
import { Typography } from '../../../../components/ui/Typography';
import { useAuth } from '../../../../hooks/useAuth';
import { toast } from 'react-hot-toast';

const steps = [
  { id: 'basic', title: 'Basic Details', component: BasicDetails },
  { id: 'location', title: 'Location', component: LocationInfo },
  { id: 'space', title: 'Space Details', component: SpaceDetails },
  { id: 'media', title: 'Photos & Videos', component: MediaUpload },
  { id: 'pricing-availability', title: 'Pricing & Availability', component: PricingAvailability },
  { id: 'amenities-rules', title: 'Amenities & Rules', component: AmenitiesRules },
];

export function AddVenueForm() {
  const [currentStep, setCurrentStep] = useState(0);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();
  const { user } = useAuth();

  const methods = useForm<Venue>({
    defaultValues: {
      ownerId: user?.uid || '',
      status: 'draft',
      searchTags: [],
      photos: [],
      amenities: [],
      rules: [],
      bookingRequirements: [],
      createdAt: new Date(),
      updatedAt: new Date(),
    }
  });

  const { handleSubmit } = methods;

  const CurrentStepComponent = steps[currentStep].component;

  const onSubmit = async (data: Venue) => {
    try {
      setIsSubmitting(true);
      
      // TODO: Implement venue creation API call
      console.log('Submitting venue:', data);
      
      // For now, just show success and redirect
      toast.success('Venue created successfully! It will be reviewed before being published.');
      navigate('/dashboard/venues');
    } catch (error) {
      console.error('Failed to create venue:', error);
      toast.error('Failed to create venue. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleNext = async () => {
    const isValid = await methods.trigger();
    if (!isValid) return;

    if (currentStep < steps.length - 1) {
      setCurrentStep(prev => prev + 1);
    }
  };

  const handleBack = () => {
    if (currentStep > 0) {
      setCurrentStep(prev => prev - 1);
    }
  };

  return (
    <div className="max-w-4xl mx-auto">
      <div className="mb-8">
        <Typography variant="h2" className="text-filliminal-yellow mb-2">
          Add New Venue
        </Typography>
        <Typography variant="body" className="text-filliminal-yellow/70">
          {steps[currentStep].title}
        </Typography>
      </div>

      <div className="mb-6">
        <div className="flex items-center">
          {steps.map((step, index) => (
            <React.Fragment key={step.id}>
              <div 
                className={`flex items-center justify-center w-8 h-8 rounded-full ${
                  index <= currentStep 
                    ? 'bg-filliminal-yellow text-filliminal-dark' 
                    : 'bg-filliminal-dark-lighter text-filliminal-yellow/50'
                }`}
              >
                {index + 1}
              </div>
              {index < steps.length - 1 && (
                <div 
                  className={`flex-1 h-0.5 mx-2 ${
                    index < currentStep 
                      ? 'bg-filliminal-yellow' 
                      : 'bg-filliminal-dark-lighter'
                  }`}
                />
              )}
            </React.Fragment>
          ))}
        </div>
      </div>

      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
          <CurrentStepComponent />

          <div className="flex justify-between mt-8">
            {currentStep > 0 && (
              <Button
                type="button"
                variant="secondary"
                onClick={handleBack}
                disabled={isSubmitting}
              >
                Back
              </Button>
            )}
            {currentStep < steps.length - 1 ? (
              <Button
                type="button"
                variant="primary"
                onClick={handleNext}
                disabled={isSubmitting}
                className="ml-auto"
              >
                Next
              </Button>
            ) : (
              <Button
                type="submit"
                variant="primary"
                disabled={isSubmitting}
                className="ml-auto"
              >
                Submit Venue
              </Button>
            )}
          </div>
        </form>
      </FormProvider>
    </div>
  );
}
