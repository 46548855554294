import { useEffect, useState } from 'react';
import { doc, onSnapshot, getDoc, DocumentReference, DocumentData } from 'firebase/firestore';
import { db } from '../lib/firebase';

interface UseFirestoreDocumentOptions {
  subscribe?: boolean;
}

export function useFirestoreDocument<T = DocumentData>(
  path: string,
  id: string,
  options: UseFirestoreDocumentOptions = {}
) {
  const [data, setData] = useState<T | null>(null);
  const [error, setError] = useState<Error | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!path || !id) {
      setData(null);
      setLoading(false);
      return;
    }

    const docRef = doc(db, path, id) as DocumentReference<T>;
    let unsubscribe: (() => void) | undefined;

    const fetchData = async () => {
      try {
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          setData(docSnap.data());
        } else {
          setData(null);
        }
        setError(null);
      } catch (err) {
        console.error(`Error fetching document ${path}/${id}:`, err);
        setError(err instanceof Error ? err : new Error(String(err)));
        // Keep the last known good data if there's an error
      } finally {
        setLoading(false);
      }
    };

    if (options.subscribe) {
      // Use real-time listener with error handling
      unsubscribe = onSnapshot(
        docRef,
        (doc) => {
          setData(doc.exists() ? doc.data() : null);
          setError(null);
          setLoading(false);
        },
        (err) => {
          console.error(`Error in document subscription ${path}/${id}:`, err);
          setError(err);
          // Don't clear data on error to maintain last known state
          setLoading(false);
        },
        // Add error handling for when the listener is inactive
        { includeMetadataChanges: true }
      );
    } else {
      // One-time fetch
      fetchData();
    }

    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [path, id, options.subscribe]);

  return { data, error, loading };
}
