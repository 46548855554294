import React from 'react';
import { Link } from 'react-router-dom';
import { cn } from '../../../../utils/cn';

interface NavItemProps {
  to: string;
  icon: React.ElementType;
  label: string;
  isActive: boolean;
}

export function NavItem({ to, icon: Icon, label, isActive }: NavItemProps) {
  return (
    <Link
      to={to}
      className={cn(
        "flex items-center gap-3 px-4 py-2 rounded-lg transition-colors",
        isActive 
          ? "bg-filliminal-yellow text-filliminal-dark" 
          : "text-filliminal-yellow hover:bg-filliminal-yellow/10"
      )}
    >
      <Icon className="w-5 h-5" />
      <span>{label}</span>
    </Link>
  );
}