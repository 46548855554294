import { useEffect } from 'react';
import { useAuth } from '../../hooks/useAuth';
import type { FacebookStatusResponse } from '../../types/auth';

declare global {
  interface Window {
    fbAsyncInit: () => void;
    FB: any;
  }
}

export function FacebookSDK() {
  const { setFacebookAuthStatus } = useAuth();

  useEffect(() => {
    // Load the Facebook SDK
    const loadFacebookSDK = () => {
      const script = document.createElement('script');
      script.src = 'https://connect.facebook.net/en_US/sdk.js';
      script.async = true;
      script.defer = true;
      script.crossOrigin = 'anonymous';
      document.body.appendChild(script);
    };

    // Check Facebook login status
    const checkLoginStatus = () => {
      window.FB?.getLoginStatus((response: FacebookStatusResponse) => {
        switch (response.status) {
          case 'connected':
            // User is logged into Facebook and has authorized your app
            setFacebookAuthStatus({
              status: response.status,
              authResponse: response.authResponse,
              isAuthenticated: true
            });
            break;
          
          case 'not_authorized':
            // User is logged into Facebook but hasn't authorized your app
            setFacebookAuthStatus({
              status: response.status,
              authResponse: null,
              isAuthenticated: false
            });
            break;
          
          case 'unknown':
          default:
            // User is either not logged into Facebook or has explicitly logged out
            setFacebookAuthStatus({
              status: 'unknown',
              authResponse: null,
              isAuthenticated: false
            });
            break;
        }
      });
    };

    // Initialize Facebook SDK
    window.fbAsyncInit = function() {
      window.FB?.init({
        appId: import.meta.env.VITE_FACEBOOK_APP_ID,
        cookie: true,
        xfbml: true,
        version: 'v18.0'
      });

      // Parse XFBML after initialization
      window.FB?.XFBML.parse();

      // Check login status immediately after initialization
      checkLoginStatus();
    };

    if (!document.getElementById('facebook-jssdk')) {
      loadFacebookSDK();
    }

    return () => {
      // Cleanup if needed
      const script = document.getElementById('facebook-jssdk');
      if (script) {
        script.remove();
      }
    };
  }, [setFacebookAuthStatus]);

  return null;
}
