import React, { useState } from 'react';
import { Link, Outlet, useLocation } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Mail, ArrowLeft, AlertCircle } from 'lucide-react';
import { Typography } from '../../components/ui/Typography';
import { Button } from '../../components/ui/Button';
import { emailSchema } from '../../lib/validations/auth';
import { ResetSuccess } from './ResetSuccess';

export function ForgotPassword() {
  const location = useLocation();
  const isMainForgotPassword = location.pathname === '/forgot-password';

  if (isMainForgotPassword) {
    return (
      <div className="min-h-screen bg-filliminal-dark flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
        <div className="max-w-md w-full space-y-8">
          <ForgotPasswordForm />
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-filliminal-dark flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full space-y-8">
        <Outlet />
      </div>
    </div>
  );
}

function ForgotPasswordForm() {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [submittedEmail, setSubmittedEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<{ email: string }>({
    resolver: zodResolver(emailSchema)
  });

  const onSubmit = async (data: { email: string }) => {
    try {
      setIsLoading(true);
      // TODO: Implement password reset logic
      setSubmittedEmail(data.email);
      setIsSubmitted(true);
    } catch (error) {
      console.error('Password reset request failed:', error);
    } finally {
      setIsLoading(false);
    }
  };

  if (isSubmitted) {
    return <ResetSuccess email={submittedEmail} />;
  }

  return (
    <div>
      <div className="text-center space-y-2">
        <Typography variant="h1" className="text-4xl">Reset Password</Typography>
        <Typography variant="body" className="text-filliminal-yellow/70">
          Enter your email address and we'll send you instructions to reset your password
        </Typography>
      </div>

      <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
        <div>
          <div className="relative">
            <input
              {...register('email')}
              type="email"
              placeholder="Email address"
              className="w-full px-4 py-3 pl-10 bg-filliminal-dark-lighter text-filliminal-yellow border border-filliminal-yellow/20 rounded-lg focus:outline-none focus:border-filliminal-yellow focus:ring-1 focus:ring-filliminal-yellow placeholder-filliminal-yellow/50"
            />
            <Mail className="absolute left-3 top-3.5 h-5 w-5 text-filliminal-yellow/50" />
          </div>
          {errors.email && (
            <div className="mt-2 flex items-center text-red-500 text-sm">
              <AlertCircle className="w-4 h-4 mr-1" />
              {errors.email.message}
            </div>
          )}
        </div>

        <Button
          type="submit"
          variant="primary"
          className="w-full bg-filliminal-yellow text-filliminal-dark hover:bg-opacity-90"
          disabled={isLoading}
        >
          {isLoading ? 'Sending Instructions...' : 'Send Reset Instructions'}
        </Button>
      </form>

      <div className="text-center">
        <Link 
          to="/login" 
          className="inline-flex items-center text-filliminal-yellow hover:text-filliminal-yellow/80"
        >
          <ArrowLeft className="w-4 h-4 mr-2" />
          Back to Login
        </Link>
      </div>
    </div>
  );
}