import React, { useState, lazy, Suspense, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { MapPin, Users, Clock } from 'lucide-react';
import { Typography } from '../components/ui/Typography';
import { useVenueData } from '../hooks/useVenueData';
import { mockVenues } from '../lib/mocks/venues';

// Lazy load components
const VenueGallery = lazy(() => import('../components/venue/VenueGallery'));
const VenueAmenities = lazy(() => import('../components/venue/VenueAmenities'));
const VenueRules = lazy(() => import('../components/venue/VenueRules'));
const VenueReviews = lazy(() => import('../components/venue/VenueReviews'));
const VenueBookingCard = lazy(() => import('../components/venue/VenueBookingCard'));

function LoadingPlaceholder() {
  return (
    <div className="animate-pulse">
      <div className="h-64 bg-filliminal-yellow/10 rounded-lg mb-8"></div>
      <div className="space-y-4">
        <div className="h-8 bg-filliminal-yellow/10 rounded w-3/4"></div>
        <div className="h-4 bg-filliminal-yellow/10 rounded w-1/2"></div>
      </div>
    </div>
  );
}

function LoadingFallback() {
  return (
    <div className="min-h-screen bg-filliminal-dark flex items-center justify-center">
      <div className="max-w-md w-full mx-auto p-6">
        <Typography variant="h2" className="text-filliminal-yellow mb-4">
          Loading Venue...
        </Typography>
      </div>
    </div>
  );
}

export function VenueListing() {
  const { id } = useParams();
  const { data: venue, error, loading } = useVenueData(id!, true);
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);

  // Handle loading and error states
  if (loading) {
    return <LoadingFallback />;
  }

  if (error || !venue) {
    return (
      <div className="min-h-screen bg-filliminal-dark flex items-center justify-center">
        <div className="max-w-md w-full mx-auto p-6">
          <Typography variant="h2" className="text-filliminal-yellow mb-4">
            Error Loading Venue
          </Typography>
          <Typography variant="body" className="text-filliminal-yellow/70">
            {error?.message || 'Venue not found'}
          </Typography>
        </div>
      </div>
    );
  }

  const handleDateSelect = useCallback((date: Date | null) => {
    setSelectedDate(date);
  }, []);

  const venueInfo = (
    <div className="mt-8 space-y-8">
      <div>
        <Typography variant="h1" className="mb-4">{venue.name}</Typography>
        <div className="flex items-center gap-4 text-filliminal-yellow/70">
          <div className="flex items-center gap-1">
            <MapPin className="w-5 h-5" />
            <span>{venue.location.city}, {venue.location.state}</span>
          </div>
          <div className="flex items-center gap-1">
            <Clock className="w-5 h-5" />
            <span>Min. {venue.availability.minimumDuration}h booking</span>
          </div>
          <div className="flex items-center gap-1">
            <Users className="w-5 h-5" />
            <span>{venue.category}</span>
          </div>
        </div>
      </div>

      <div className="card">
        <div className="p-6">
          <Typography variant="h3" className="mb-4">About this space</Typography>
          <Typography variant="body" className="text-filliminal-yellow/90">
            {venue.description}
          </Typography>
          <div className="mt-4">
            <Typography variant="small" className="text-filliminal-yellow/70">
              Tags: {venue.searchTags.join(', ')}
            </Typography>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
      <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
        <div className="lg:col-span-2">
          <Suspense fallback={<LoadingPlaceholder />}>
            <VenueGallery images={venue.photos.map(photo => photo.url)} />
            {venueInfo}
            <VenueAmenities amenities={venue.amenities} />
            <VenueRules rules={venue.rules} />
            <VenueReviews venueId={venue.id || ''} />
          </Suspense>
        </div>
        
        <div className="lg:col-span-1">
          <Suspense fallback={<LoadingPlaceholder />}>
            <VenueBookingCard
              venue={venue}
              selectedDate={selectedDate}
              onDateSelect={handleDateSelect}
            />
          </Suspense>
        </div>
      </div>
    </div>
  );
}