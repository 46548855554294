import { useState, useEffect, useCallback } from 'react';
import { useAuth } from './useAuth';
import { dashboardService } from '../services/dashboardService';

interface DashboardState {
  isLoading: boolean;
  error: Error | null;
  data: any | null;
  isStale: boolean;
}

export function useDashboard() {
  const { user } = useAuth();
  const [state, setState] = useState<DashboardState>({
    isLoading: true,
    error: null,
    data: null,
    isStale: false
  });

  const fetchData = useCallback(async (silent = false) => {
    if (!user?.uid) {
      setState(prev => ({
        ...prev,
        isLoading: false,
        error: new Error('User not authenticated')
      }));
      return;
    }

    if (!silent) {
      setState(prev => ({
        ...prev,
        isLoading: true,
        error: null
      }));
    }

    try {
      const data = await dashboardService.getDashboardData(user.uid);
      setState(prev => ({
        ...prev,
        data,
        isLoading: false,
        error: null,
        isStale: false
      }));
    } catch (error) {
      setState(prev => ({
        ...prev,
        error: error as Error,
        isLoading: false
      }));
    }
  }, [user?.uid]);

  // Initial fetch
  useEffect(() => {
    fetchData();
  }, [fetchData]);

  // Set up periodic refresh
  useEffect(() => {
    if (!user?.uid) return;

    const refreshInterval = setInterval(() => {
      setState(prev => ({ ...prev, isStale: true }));
      fetchData(true); // Silent refresh
    }, 5 * 60 * 1000); // Refresh every 5 minutes

    return () => clearInterval(refreshInterval);
  }, [user?.uid, fetchData]);

  const refresh = useCallback(() => {
    return fetchData();
  }, [fetchData]);

  return {
    ...state,
    refresh
  };
}
