import React from 'react';
import { Typography } from '../../components/ui/Typography';

export function EventBuilder() {
  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
      <div className="space-y-6">
        <Typography variant="h1" className="text-filliminal-yellow">
          Event Builder
        </Typography>
        <Typography variant="body" className="text-filliminal-yellow/80">
          Coming soon! Build your perfect event by combining venues, services, and professionals.
        </Typography>
      </div>
    </div>
  );
}
