import React from 'react';
import { useFormContext } from 'react-hook-form';
import { Card } from '../../../../../components/ui/Card';
import { Input } from '../../../../../components/ui/Input';
import { Select } from '../../../../../components/ui/Select';
import { Typography } from '../../../../../components/ui/Typography';
import { Button } from '../../../../../components/ui/Button';

const currencies = [
  { value: 'USD', label: 'USD ($)' },
  { value: 'EUR', label: 'EUR (€)' },
  { value: 'GBP', label: 'GBP (£)' },
];

const rateTypes = [
  { value: 'hourly', label: 'Per Hour' },
  { value: 'daily', label: 'Per Day' },
  { value: 'weekly', label: 'Per Week' },
];

const daysOfWeek = [
  { value: 'monday', label: 'Monday' },
  { value: 'tuesday', label: 'Tuesday' },
  { value: 'wednesday', label: 'Wednesday' },
  { value: 'thursday', label: 'Thursday' },
  { value: 'friday', label: 'Friday' },
  { value: 'saturday', label: 'Saturday' },
  { value: 'sunday', label: 'Sunday' },
];

export function PricingAvailability() {
  const { register, watch, setValue } = useFormContext();
  const [specialRates, setSpecialRates] = React.useState<any[]>([]);

  const handleAddSpecialRate = () => {
    setSpecialRates([...specialRates, {
      name: '',
      amount: '',
      startDate: '',
      endDate: '',
      daysOfWeek: [],
    }]);
  };

  const handleRemoveSpecialRate = (index: number) => {
    setSpecialRates(specialRates.filter((_, i) => i !== index));
  };

  return (
    <div className="space-y-8">
      {/* Base Rate Section */}
      <Card className="p-6">
        <Typography variant="h3" className="text-filliminal-yellow mb-6">
          Base Rate
        </Typography>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          <div>
            <Input
              label="Amount"
              type="number"
              {...register('baseRate.amount')}
              min={0}
            />
          </div>
          <div>
            <Select
              label="Currency"
              options={currencies}
              {...register('baseRate.currency')}
            />
          </div>
          <div>
            <Select
              label="Rate Type"
              options={rateTypes}
              {...register('baseRate.type')}
            />
          </div>
        </div>
      </Card>

      {/* Special Rates Section */}
      <Card className="p-6">
        <div className="flex justify-between items-center mb-6">
          <Typography variant="h3" className="text-filliminal-yellow">
            Special Rates
          </Typography>
          <Button
            variant="secondary"
            size="sm"
            onClick={handleAddSpecialRate}
          >
            Add Special Rate
          </Button>
        </div>
        
        <div className="space-y-6">
          {specialRates.map((rate, index) => (
            <div key={index} className="p-4 border border-filliminal-yellow/20 rounded-lg">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
                <Input
                  label="Rate Name"
                  {...register(`specialRates.${index}.name`)}
                />
                <Input
                  label="Amount"
                  type="number"
                  {...register(`specialRates.${index}.amount`)}
                  min={0}
                />
              </div>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
                <Input
                  label="Start Date"
                  type="date"
                  {...register(`specialRates.${index}.startDate`)}
                />
                <Input
                  label="End Date"
                  type="date"
                  {...register(`specialRates.${index}.endDate`)}
                />
              </div>
              <Select
                label="Days of Week"
                options={daysOfWeek}
                isMulti
                {...register(`specialRates.${index}.daysOfWeek`)}
              />
              <Button
                variant="danger"
                size="sm"
                className="mt-4"
                onClick={() => handleRemoveSpecialRate(index)}
              >
                Remove Rate
              </Button>
            </div>
          ))}
        </div>
      </Card>

      {/* Availability Section */}
      <Card className="p-6">
        <Typography variant="h3" className="text-filliminal-yellow mb-6">
          Availability
        </Typography>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
          <Input
            label="Operating Hours Start"
            type="time"
            {...register('availability.operatingHours.start')}
          />
          <Input
            label="Operating Hours End"
            type="time"
            {...register('availability.operatingHours.end')}
          />
        </div>
        <div className="mb-6">
          <Select
            label="Available Days"
            options={daysOfWeek}
            isMulti
            {...register('availability.availableDays')}
          />
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <Input
            label="Minimum Booking Duration (hours)"
            type="number"
            {...register('availability.minDuration')}
            min={1}
          />
          <Input
            label="Maximum Booking Duration (hours)"
            type="number"
            {...register('availability.maxDuration')}
            min={1}
          />
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mt-6">
          <Input
            label="Minimum Notice Period (hours)"
            type="number"
            {...register('availability.minNotice')}
            min={0}
          />
          <Input
            label="Maximum Notice Period (days)"
            type="number"
            {...register('availability.maxNotice')}
            min={1}
          />
        </div>
      </Card>
    </div>
  );
}
