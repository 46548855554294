import React, { useState, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useAuthContext } from '../../providers/AuthProvider';
import { auth } from '../../lib/firebase';
import { signOut } from 'firebase/auth';
import { 
  Menu, X, User, LogOut, Settings, Heart, LayoutDashboard,
  Home, Building2, Calendar, BarChart2, Wallet,
  BookMarked, Star, MessageSquare, Bell
} from 'lucide-react';
import { useClickOutside } from '../../hooks/useClickOutside';
import { navigationConfig } from '../../pages/Dashboard/config/navigation';

export function Header() {
  const { user } = useAuthContext();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const dropdownRef = useClickOutside(() => setIsDropdownOpen(false));
  const mobileMenuRef = useClickOutside(() => setIsMobileMenuOpen(false));
  const location = useLocation();

  const handleLogout = async () => {
    try {
      await signOut(auth);
      setIsDropdownOpen(false);
      setIsMobileMenuOpen(false);
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  const isActive = (path: string) => location.pathname.includes(path);

  return (
    <header className="fixed top-0 left-0 right-0 bg-filliminal-dark border-b border-filliminal-yellow/20 z-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between items-center h-16">
          <div className="flex items-center">
            <Link to="/" className="flex items-center space-x-2" onClick={() => setIsMobileMenuOpen(false)}>
              <span className="logo-text text-xl text-filliminal-yellow">
                F·I·L·L·I·M·I·N·A·L
              </span>
            </Link>
          </div>

          <div className="hidden md:flex items-center space-x-6">
            <Link to="/search" className="text-filliminal-yellow hover:text-filliminal-yellow/80 transition-colors">
              Find a Space
            </Link>
            <Link to="/event-builder" className="text-filliminal-yellow hover:text-filliminal-yellow/80 transition-colors">
              Event Builder
            </Link>
            <Link to="/hire-pros" className="text-filliminal-yellow hover:text-filliminal-yellow/80 transition-colors">
              Hire Pros
            </Link>
            <Link to="/list-space" className="text-filliminal-yellow hover:text-filliminal-yellow/80 transition-colors">
              List your Space
            </Link>

            {user ? (
              <div className="relative" ref={dropdownRef}>
                <button
                  onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                  className="flex items-center space-x-2 text-filliminal-yellow hover:text-filliminal-yellow/80 transition-colors"
                >
                  <User className="w-5 h-5" />
                  <span>{user.displayName || 'Account'}</span>
                </button>

                {isDropdownOpen && (
                  <div className="absolute right-0 mt-2 w-48 bg-filliminal-dark-lighter border border-filliminal-yellow/20 rounded-lg shadow-lg py-1">
                    <Link
                      to="/dashboard"
                      className="flex items-center px-4 py-2 text-filliminal-yellow hover:bg-filliminal-yellow/10"
                      onClick={() => setIsDropdownOpen(false)}
                    >
                      <LayoutDashboard className="w-4 h-4 mr-2" />
                      Dashboard
                    </Link>
                    <button
                      onClick={handleLogout}
                      className="flex items-center w-full px-4 py-2 text-filliminal-yellow hover:bg-filliminal-yellow/10"
                    >
                      <LogOut className="w-4 h-4 mr-2" />
                      Sign Out
                    </button>
                  </div>
                )}
              </div>
            ) : (
              <div className="flex items-center space-x-4">
                <Link
                  to="/login"
                  className="text-filliminal-yellow hover:text-filliminal-yellow/80 transition-colors"
                >
                  Sign In
                </Link>
                <Link
                  to="/signup"
                  className="px-4 py-2 bg-filliminal-yellow text-filliminal-dark rounded-lg hover:bg-filliminal-yellow/90 transition-colors"
                >
                  Sign Up
                </Link>
              </div>
            )}
          </div>

          {/* Mobile menu button */}
          <div className="md:hidden">
            <button
              onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
              className="text-filliminal-yellow hover:text-filliminal-yellow/80"
            >
              {isMobileMenuOpen ? <X className="w-6 h-6" /> : <Menu className="w-6 h-6" />}
            </button>
          </div>
        </div>
      </div>

      {/* Mobile menu */}
      {isMobileMenuOpen && (
        <div className="md:hidden" ref={mobileMenuRef}>
          <div className="px-2 pt-2 pb-3 space-y-1 bg-filliminal-dark border-t border-filliminal-yellow/20">
            <Link
              to="/search"
              className="block px-3 py-2 text-filliminal-yellow hover:bg-filliminal-yellow/10 rounded-lg"
              onClick={() => setIsMobileMenuOpen(false)}
            >
              Find a Space
            </Link>
            <Link
              to="/event-builder"
              className="block px-3 py-2 text-filliminal-yellow hover:bg-filliminal-yellow/10 rounded-lg"
              onClick={() => setIsMobileMenuOpen(false)}
            >
              Event Builder
            </Link>
            <Link
              to="/hire-pros"
              className="block px-3 py-2 text-filliminal-yellow hover:bg-filliminal-yellow/10 rounded-lg"
              onClick={() => setIsMobileMenuOpen(false)}
            >
              Hire Pros
            </Link>
            <Link
              to="/list-space"
              className="block px-3 py-2 text-filliminal-yellow hover:bg-filliminal-yellow/10 rounded-lg"
              onClick={() => setIsMobileMenuOpen(false)}
            >
              List your Space
            </Link>

            {user ? (
              <>
                <Link
                  to="/dashboard"
                  className="block px-3 py-2 text-filliminal-yellow hover:bg-filliminal-yellow/10 rounded-lg"
                  onClick={() => setIsMobileMenuOpen(false)}
                >
                  Dashboard
                </Link>
                <button
                  onClick={() => {
                    handleLogout();
                    setIsMobileMenuOpen(false);
                  }}
                  className="block w-full text-left px-3 py-2 text-filliminal-yellow hover:bg-filliminal-yellow/10 rounded-lg"
                >
                  Sign Out
                </button>
              </>
            ) : (
              <>
                <Link
                  to="/login"
                  className="block px-3 py-2 text-filliminal-yellow hover:bg-filliminal-yellow/10 rounded-lg"
                  onClick={() => setIsMobileMenuOpen(false)}
                >
                  Sign In
                </Link>
                <Link
                  to="/signup"
                  className="block px-3 py-2 text-filliminal-yellow hover:bg-filliminal-yellow/10 rounded-lg"
                  onClick={() => setIsMobileMenuOpen(false)}
                >
                  Sign Up
                </Link>
              </>
            )}
          </div>
        </div>
      )}
    </header>
  );
}