import React from 'react';
import { Link } from 'react-router-dom';
import { X } from 'lucide-react';
import { cn } from '../../../../utils/cn';
import { NavItem } from './NavItem';
import { NavSection } from './NavSection';
import { UserProfile } from './UserProfile';
import { navigationConfig } from '../../config/navigation';

interface SidebarProps {
  isOpen: boolean;
  onClose: () => void;
  currentPath: string;
}

export function Sidebar({ isOpen, onClose, currentPath }: SidebarProps) {
  const isActive = (path: string) => currentPath.includes(path);

  return (
    <aside className={cn(
      "fixed inset-y-0 left-0 z-50 w-64 bg-filliminal-dark-lighter border-r border-filliminal-yellow/20 transition-transform duration-200 lg:relative lg:translate-x-0",
      !isOpen && "-translate-x-full"
    )}>
      <div className="flex flex-col h-full">
        <div className="p-4 border-b border-filliminal-yellow/20">
          <div className="flex items-center justify-between">
            <Link to="/" className="text-xl font-bold text-filliminal-yellow">
              F·I·L·L·I·M·I·N·A·L
            </Link>
            <button 
              onClick={onClose}
              className="lg:hidden text-filliminal-yellow"
            >
              <X className="w-6 h-6" />
            </button>
          </div>
        </div>

        <div className="flex-1 overflow-y-auto p-4 space-y-8">
          <NavItem 
            to="/dashboard" 
            icon={navigationConfig.overview.icon} 
            label={navigationConfig.overview.label} 
            isActive={currentPath === '/dashboard'} 
          />
          
          <NavSection title="Host">
            {navigationConfig.host.map((item) => (
              <NavItem
                key={item.path}
                to={`/dashboard/${item.path}`}
                icon={item.icon}
                label={item.label}
                isActive={isActive(item.path)}
              />
            ))}
          </NavSection>

          <NavSection title="Guest">
            {navigationConfig.guest.map((item) => (
              <NavItem
                key={item.path}
                to={`/dashboard/${item.path}`}
                icon={item.icon}
                label={item.label}
                isActive={isActive(item.path)}
              />
            ))}
          </NavSection>

          <NavSection title="General">
            {navigationConfig.shared.map((item) => (
              <NavItem
                key={item.path}
                to={`/dashboard/${item.path}`}
                icon={item.icon}
                label={item.label}
                isActive={isActive(item.path)}
              />
            ))}
          </NavSection>
        </div>

        <div className="p-4 border-t border-filliminal-yellow/20">
          <UserProfile />
        </div>
      </div>
    </aside>
  );
}