import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Building2, DollarSign, Shield, Calendar } from 'lucide-react';
import { Typography } from '../../components/ui/Typography';
import { Button } from '../../components/ui/Button';
import { useAuth } from '../../hooks/useAuth';

export function HostLanding() {
  const { user } = useAuth();
  const navigate = useNavigate();

  const handleGetStarted = () => {
    if (user) {
      // Navigate directly to the add venue form
      navigate('/dashboard/venues/new', { replace: true });
    } else {
      navigate('/signup', { 
        state: { 
          redirectTo: '/dashboard/venues/new',
          message: 'Sign up to list your space' 
        }
      });
    }
  };

  const benefits = [
    {
      icon: DollarSign,
      title: 'Earn Extra Income',
      description: 'Turn your unused space into a revenue stream during off-peak hours.'
    },
    {
      icon: Calendar,
      title: 'Flexible Scheduling',
      description: 'You control your availability. Only host when it works for you.'
    },
    {
      icon: Shield,
      title: 'Safe & Secure',
      description: 'Verified guests, secure payments, and insurance coverage for peace of mind.'
    },
    {
      icon: Building2,
      title: 'Simple Management',
      description: 'Easy-to-use tools for bookings, payments, and guest communication.'
    }
  ];

  return (
    <div className="min-h-screen bg-filliminal-dark">
      <div className="max-w-7xl mx-auto px-4 py-16 space-y-16">
        <div className="text-center space-y-4">
          <Typography variant="h1" className="text-5xl md:text-6xl">
            Share Your Space,<br />Grow Your Revenue
          </Typography>
          <Typography variant="body" className="text-xl text-filliminal-yellow/70 max-w-2xl mx-auto">
            Join thousands of hosts earning extra income by sharing their spaces during off-peak hours.
          </Typography>
          <div className="pt-8">
            <Button 
              variant="primary" 
              size="lg"
              onClick={handleGetStarted}
            >
              {user ? 'List Your Space' : 'Get Started'}
            </Button>
          </div>
        </div>

        <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-8">
          {benefits.map((benefit) => (
            <div 
              key={benefit.title}
              className="bg-filliminal-dark-lighter border border-filliminal-yellow/20 rounded-lg p-6 space-y-4"
            >
              <div className="w-12 h-12 bg-filliminal-yellow/10 rounded-lg flex items-center justify-center">
                <benefit.icon className="w-6 h-6 text-filliminal-yellow" />
              </div>
              <Typography variant="h4">{benefit.title}</Typography>
              <Typography variant="body" className="text-filliminal-yellow/70">
                {benefit.description}
              </Typography>
            </div>
          ))}
        </div>

        <div className="bg-filliminal-dark-lighter border border-filliminal-yellow/20 rounded-lg p-8 md:p-12">
          <div className="grid md:grid-cols-2 gap-8 items-center">
            <div className="space-y-6">
              <Typography variant="h2">Ready to Start Hosting?</Typography>
              <Typography variant="body" className="text-filliminal-yellow/70">
                Create your account now and start listing your space in minutes. Our team will guide you through every step of the process.
              </Typography>
              <Button 
                variant="primary" 
                size="lg"
                onClick={handleGetStarted}
              >
                {user ? 'List Your Space' : 'Create Your Account'}
              </Button>
            </div>
            <div className="aspect-video bg-filliminal-dark rounded-lg overflow-hidden">
              <img 
                src="https://images.unsplash.com/photo-1497366216548-37526070297c"
                alt="Modern space interior"
                className="w-full h-full object-cover"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}