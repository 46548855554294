import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Lock, AlertCircle, Info, Check, X } from 'lucide-react';
import { Typography } from '../../components/ui/Typography';
import { Button } from '../../components/ui/Button';
import { passwordCreationSchema } from '../../lib/validations/auth';

interface PasswordCreationProps {
  email: string;
  onPasswordSubmit: (data: { password: string; confirmPassword: string }) => void;
  isLoading: boolean;
}

interface PasswordCriteria {
  minLength: boolean;
  hasUppercase: boolean;
  hasNumber: boolean;
  hasSymbol: boolean;
}

export function PasswordCreation({ email, onPasswordSubmit, isLoading }: PasswordCreationProps) {
  const [passwordCriteria, setPasswordCriteria] = useState<PasswordCriteria>({
    minLength: false,
    hasUppercase: false,
    hasNumber: false,
    hasSymbol: false,
  });

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors }
  } = useForm<{ password: string; confirmPassword: string }>({
    resolver: zodResolver(passwordCreationSchema)
  });

  const password = watch('password', '');

  React.useEffect(() => {
    setPasswordCriteria({
      minLength: password.length >= 8,
      hasUppercase: /[A-Z]/.test(password),
      hasNumber: /[0-9]/.test(password),
      hasSymbol: /[^A-Za-z0-9]/.test(password),
    });
  }, [password]);

  const CriteriaItem = ({ met, label }: { met: boolean; label: string }) => (
    <div className="flex items-center gap-2">
      {met ? (
        <Check className="w-4 h-4 text-green-500" />
      ) : (
        <X className="w-4 h-4 text-red-500" />
      )}
      <span className={`text-sm ${met ? 'text-green-500' : 'text-red-500'}`}>
        {label}
      </span>
    </div>
  );

  return (
    <div className="space-y-6">
      <div className="text-center">
        <Typography variant="h1" className="text-4xl mb-2">Create Password</Typography>
        <Typography variant="body" className="text-filliminal-yellow/70">
          {email}
        </Typography>
      </div>

      <div className="bg-filliminal-dark-lighter border border-filliminal-yellow/20 rounded-lg p-4">
        <div className="flex items-start gap-2">
          <Info className="w-5 h-5 text-filliminal-yellow mt-0.5" />
          <div className="space-y-2">
            <Typography variant="body" className="font-medium mb-2">Password Requirements:</Typography>
            <div className="space-y-1">
              <CriteriaItem met={passwordCriteria.minLength} label="Minimum 8 characters" />
              <CriteriaItem met={passwordCriteria.hasUppercase} label="At least one uppercase letter" />
              <CriteriaItem met={passwordCriteria.hasNumber} label="At least one number" />
              <CriteriaItem met={passwordCriteria.hasSymbol} label="At least one symbol" />
            </div>
          </div>
        </div>
      </div>

      <form onSubmit={handleSubmit(onPasswordSubmit)} className="space-y-6">
        <div>
          <div className="relative">
            <input
              {...register('password')}
              type="password"
              placeholder="Password"
              className="w-full px-4 py-3 pl-10 bg-filliminal-dark-lighter text-filliminal-yellow border border-filliminal-yellow/20 rounded-lg"
            />
            <Lock className="absolute left-3 top-3.5 h-5 w-5 text-filliminal-yellow/50" />
          </div>
          {errors.password && (
            <div className="mt-2 flex items-center text-red-500 text-sm">
              <AlertCircle className="w-4 h-4 mr-1" />
              {errors.password.message}
            </div>
          )}
        </div>

        <div>
          <div className="relative">
            <input
              {...register('confirmPassword')}
              type="password"
              placeholder="Confirm password"
              className="w-full px-4 py-3 pl-10 bg-filliminal-dark-lighter text-filliminal-yellow border border-filliminal-yellow/20 rounded-lg"
            />
            <Lock className="absolute left-3 top-3.5 h-5 w-5 text-filliminal-yellow/50" />
          </div>
          {errors.confirmPassword && (
            <div className="mt-2 flex items-center text-red-500 text-sm">
              <AlertCircle className="w-4 h-4 mr-1" />
              {errors.confirmPassword.message}
            </div>
          )}
        </div>

        <Button
          type="submit"
          variant="primary"
          className="w-full bg-filliminal-yellow text-filliminal-dark hover:bg-opacity-90"
          disabled={isLoading}
        >
          {isLoading ? 'Creating Account...' : 'Create Account'}
        </Button>
      </form>
    </div>
  );
}