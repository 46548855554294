import React from 'react';
import { useNavigate, Outlet } from 'react-router-dom';
import { useAuth } from '../../lib/hooks/useAuth';
import { HostLanding } from './HostLanding';
import { HostActivation } from './HostActivation';

export function ListSpace() {
  const { user } = useAuth();
  const navigate = useNavigate();

  // Show landing page for non-authenticated users
  if (!user) {
    return (
      <div className="min-h-screen bg-filliminal-dark">
        <HostLanding />
      </div>
    );
  }

  // Show activation page for authenticated but unverified users
  if (user.needsVerification || user.role === 'unverified') {
    return (
      <div className="min-h-screen bg-filliminal-dark">
        <HostActivation />
      </div>
    );
  }

  // Redirect to the actual listing form for verified users
  navigate('/dashboard/venues/new');
  return null;
}