import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Building2, FileCheck, BadgeCheck } from 'lucide-react';
import { Typography } from '../../components/ui/Typography';
import { Button } from '../../components/ui/Button';

export function HostActivation() {
  const navigate = useNavigate();
  const [step, setStep] = React.useState(1);

  const handleActivation = () => {
    // TODO: Implement host role activation
    navigate('/dashboard/venues/new');
  };

  return (
    <div className="min-h-screen bg-filliminal-dark flex items-center justify-center p-4">
      <div className="max-w-xl w-full space-y-8">
        <div className="text-center space-y-4">
          <Typography variant="h1">Become a Host</Typography>
          <Typography variant="body" className="text-filliminal-yellow/70">
            Complete these steps to start listing your spaces
          </Typography>
        </div>

        <div className="bg-filliminal-dark-lighter border border-filliminal-yellow/20 rounded-lg p-6 space-y-6">
          <div className="space-y-4">
            <div className="flex items-center gap-4">
              <div className="w-10 h-10 rounded-full bg-filliminal-yellow/10 flex items-center justify-center">
                <Building2 className="w-5 h-5 text-filliminal-yellow" />
              </div>
              <div>
                <Typography variant="h4">Business Information</Typography>
                <Typography variant="small" className="text-filliminal-yellow/70">
                  Tell us about your business
                </Typography>
              </div>
            </div>

            <div className="flex items-center gap-4">
              <div className="w-10 h-10 rounded-full bg-filliminal-yellow/10 flex items-center justify-center">
                <FileCheck className="w-5 h-5 text-filliminal-yellow" />
              </div>
              <div>
                <Typography variant="h4">Host Guidelines</Typography>
                <Typography variant="small" className="text-filliminal-yellow/70">
                  Review and accept our hosting policies
                </Typography>
              </div>
            </div>

            <div className="flex items-center gap-4">
              <div className="w-10 h-10 rounded-full bg-filliminal-yellow/10 flex items-center justify-center">
                <BadgeCheck className="w-5 h-5 text-filliminal-yellow" />
              </div>
              <div>
                <Typography variant="h4">Verification</Typography>
                <Typography variant="small" className="text-filliminal-yellow/70">
                  Complete identity verification
                </Typography>
              </div>
            </div>
          </div>

          <Button 
            variant="primary" 
            className="w-full"
            onClick={handleActivation}
          >
            Start Verification
          </Button>
        </div>
      </div>
    </div>
  );
}