import { createContext, useContext, useEffect, useState, ReactNode, useMemo } from 'react';
import { onAuthStateChanged, User as FirebaseUser } from 'firebase/auth';
import { auth } from '../lib/firebase';
import { useNavigate, useLocation } from 'react-router-dom';
import { User } from '../types';

// Only redirect to login for these paths when not authenticated
const PROTECTED_PATHS = ['/dashboard', '/list-space'];

interface AuthContextType {
  user: User | null;
  loading: boolean;
}

export const AuthContext = createContext<AuthContextType>({ 
  user: null, 
  loading: true
});

export function useAuthContext() {
  return useContext(AuthContext);
}

interface AuthProviderProps {
  children: ReactNode;
}

export function AuthProvider({ children }: AuthProviderProps) {
  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    let mounted = true;
    
    const unsubscribe = onAuthStateChanged(auth, async (firebaseUser) => {
      try {
        if (!mounted) return;
        
        if (firebaseUser) {
          const user: User = {
            uid: firebaseUser.uid,
            email: firebaseUser.email,
            emailVerified: firebaseUser.emailVerified,
            isAnonymous: firebaseUser.isAnonymous,
            role: 'guest',
            needsVerification: !firebaseUser.emailVerified,
            metadata: {
              creationTime: firebaseUser.metadata.creationTime || new Date().toISOString(),
              lastSignInTime: firebaseUser.metadata.lastSignInTime || new Date().toISOString(),
            },
            profile: {
              firstName: '',
              lastName: '',
              phoneNumber: '',
            },
            providerData: firebaseUser.providerData,
            refreshToken: firebaseUser.refreshToken,
            tenantId: firebaseUser.tenantId,
          };
          setUser(user);
        } else {
          setUser(null);
          if (PROTECTED_PATHS.some(path => location.pathname.startsWith(path))) {
            navigate('/login', { 
              state: { redirectTo: location.pathname },
              replace: true 
            });
          }
        }
      } catch (error) {
        console.error('Auth state change error:', error);
        setUser(null);
      } finally {
        if (mounted) {
          setLoading(false);
        }
      }
    });

    return () => {
      mounted = false;
      unsubscribe();
    };
  }, [navigate, location.pathname]);

  const value = useMemo(() => ({
    user,
    loading
  }), [user, loading]);

  if (loading) {
    return (
      <div className="min-h-screen bg-filliminal-dark flex items-center justify-center">
        <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-filliminal-yellow"></div>
      </div>
    );
  }

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
}
