import React from 'react';
import { useFormContext } from 'react-hook-form';
import type { Venue } from '../../../../../types/venue';
import { Input } from '../../../../../components/ui/Input';
import { Select } from '../../../../../components/ui/Select';
import { Textarea } from '../../../../../components/ui/Textarea';
import { Typography } from '../../../../../components/ui/Typography';

const venueCategories = [
  { value: 'event-space', label: 'Event Space' },
  { value: 'conference-room', label: 'Conference Room' },
  { value: 'studio', label: 'Studio' },
  { value: 'performance-venue', label: 'Performance Venue' },
  { value: 'outdoor-space', label: 'Outdoor Space' },
  { value: 'pop-up-retail', label: 'Pop-up Retail' },
  { value: 'gallery', label: 'Gallery' },
  { value: 'workshop', label: 'Workshop' },
];

const commonAmenities = [
  { id: 'wifi', name: 'WiFi', category: 'Technology' },
  { id: 'parking', name: 'Parking', category: 'Facilities' },
  { id: 'kitchen', name: 'Kitchen', category: 'Facilities' },
  { id: 'bathroom', name: 'Bathroom', category: 'Facilities' },
  { id: 'sound-system', name: 'Sound System', category: 'Equipment' },
  { id: 'projector', name: 'Projector', category: 'Equipment' },
  { id: 'tables', name: 'Tables', category: 'Furniture' },
  { id: 'chairs', name: 'Chairs', category: 'Furniture' },
  { id: 'stage', name: 'Stage', category: 'Facilities' },
  { id: 'lighting', name: 'Lighting Equipment', category: 'Equipment' },
];

export function BasicDetails() {
  const { register, formState: { errors } } = useFormContext<Venue>();

  return (
    <div className="space-y-6">
      <div>
        <Typography variant="h3" className="text-filliminal-yellow mb-4">Basic Details</Typography>
        
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div>
            <label className="block text-filliminal-yellow mb-2">Venue Name</label>
            <Input
              {...register('name', { required: 'Venue name is required' })}
              placeholder="Enter venue name"
              error={errors.name?.message}
            />
          </div>

          <div>
            <label className="block text-filliminal-yellow mb-2">Category</label>
            <Select
              {...register('category', { required: 'Category is required' })}
              error={errors.category?.message}
            >
              <option value="">Select a category</option>
              {venueCategories.map(category => (
                <option key={category.value} value={category.value}>
                  {category.label}
                </option>
              ))}
            </Select>
          </div>
        </div>
      </div>

      <div>
        <label className="block text-filliminal-yellow mb-2">Description</label>
        <Textarea
          {...register('description', { 
            required: 'Description is required',
            minLength: { value: 100, message: 'Description must be at least 100 characters' }
          })}
          placeholder="Describe your venue..."
          rows={5}
          error={errors.description?.message}
        />
      </div>

      <div>
        <Typography variant="h4" className="text-filliminal-yellow mb-4">Amenities</Typography>
        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
          {commonAmenities.map(amenity => (
            <label key={amenity.id} className="flex items-center space-x-2">
              <input
                type="checkbox"
                {...register('amenities')}
                value={amenity.id}
                className="form-checkbox text-filliminal-yellow rounded border-filliminal-yellow/20 bg-transparent"
              />
              <span className="text-filliminal-yellow">{amenity.name}</span>
            </label>
          ))}
        </div>
      </div>

      <div>
        <Typography variant="h4" className="text-filliminal-yellow mb-4">Search Tags</Typography>
        <Input
          {...register('searchTags')}
          placeholder="Add tags (comma separated)"
          className="mb-2"
        />
        <Typography variant="caption" className="text-filliminal-yellow/70">
          Add relevant tags to help users find your venue (e.g., wedding, corporate, photography)
        </Typography>
      </div>
    </div>
  );
}
