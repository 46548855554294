import React from 'react';
import { Grid, List } from 'lucide-react';
import { Button } from '../ui/Button';
import { VenueCard } from '../venues/VenueCard';
import type { Venue } from '../../types/venue';

interface ResultsGridProps {
  venues: Venue[];
  viewMode: 'grid' | 'list';
  onViewModeChange: (mode: 'grid' | 'list') => void;
}

export function ResultsGrid({ venues, viewMode, onViewModeChange }: ResultsGridProps) {
  return (
    <div>
      <div className="flex justify-between items-center mb-6">
        <div className="text-filliminal-yellow">
          {venues.length} venues found
        </div>
        <div className="flex gap-2">
          <Button
            variant="ghost"
            size="icon"
            onClick={() => onViewModeChange('grid')}
            className={viewMode === 'grid' ? 'text-filliminal-yellow' : 'text-filliminal-yellow/50'}
          >
            <Grid className="w-5 h-5" />
          </Button>
          <Button
            variant="ghost"
            size="icon"
            onClick={() => onViewModeChange('list')}
            className={viewMode === 'list' ? 'text-filliminal-yellow' : 'text-filliminal-yellow/50'}
          >
            <List className="w-5 h-5" />
          </Button>
        </div>
      </div>

      <div className={
        viewMode === 'grid'
          ? 'grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6'
          : 'space-y-6'
      }>
        {venues.map((venue) => (
          <VenueCard
            key={venue.id}
            venue={venue}
            layout={viewMode === 'grid' ? 'grid' : 'list'}
          />
        ))}
      </div>
    </div>
  );
}