export function TermsOfService() {
  return (
    <div className="container mx-auto px-4 py-8 max-w-4xl">
      <h1 className="text-3xl font-bold mb-6 text-filliminal-yellow">Terms of Service</h1>
      
      <div className="space-y-6 text-filliminal-light">
        <section>
          <h2 className="text-2xl font-semibold mb-3 text-filliminal-yellow">Last Updated: December 7, 2023</h2>
          <p>Please read these Terms of Service ("Terms", "Terms of Service") carefully before using the Filliminal website and services.</p>
        </section>

        <section>
          <h2 className="text-xl font-semibold mb-3 text-filliminal-yellow">1. Acceptance of Terms</h2>
          <p>By accessing or using Filliminal, you agree to be bound by these Terms. If you disagree with any part of the terms, you may not access the service.</p>
        </section>

        <section>
          <h2 className="text-xl font-semibold mb-3 text-filliminal-yellow">2. Description of Service</h2>
          <p>Filliminal is a venue management platform that allows users to:</p>
          <ul className="list-disc pl-6 space-y-2">
            <li>List and discover event venues</li>
            <li>Book and manage venue reservations</li>
            <li>Connect with venue owners and event professionals</li>
            <li>Access event planning tools and resources</li>
          </ul>
        </section>

        <section>
          <h2 className="text-xl font-semibold mb-3 text-filliminal-yellow">3. User Accounts</h2>
          <ul className="list-disc pl-6 space-y-2">
            <li>You must be at least 18 years old to use this service</li>
            <li>You are responsible for maintaining the security of your account</li>
            <li>You are responsible for all activities under your account</li>
            <li>You must notify us immediately of any unauthorized use</li>
          </ul>
        </section>

        <section>
          <h2 className="text-xl font-semibold mb-3 text-filliminal-yellow">4. User Content</h2>
          <p>When posting content to Filliminal, you:</p>
          <ul className="list-disc pl-6 space-y-2">
            <li>Grant us the right to use, modify, and distribute your content</li>
            <li>Represent that you own or have rights to share the content</li>
            <li>Agree not to post harmful, illegal, or inappropriate content</li>
          </ul>
        </section>

        <section>
          <h2 className="text-xl font-semibold mb-3 text-filliminal-yellow">5. Payments and Fees</h2>
          <ul className="list-disc pl-6 space-y-2">
            <li>All fees are in USD unless otherwise stated</li>
            <li>Venue owners may set their own pricing</li>
            <li>Service fees may apply to bookings</li>
            <li>Refunds are subject to individual venue policies</li>
          </ul>
        </section>

        <section>
          <h2 className="text-xl font-semibold mb-3 text-filliminal-yellow">6. Intellectual Property</h2>
          <p>The service and its original content, features, and functionality are owned by Filliminal and are protected by international copyright, trademark, patent, trade secret, and other intellectual property laws.</p>
        </section>

        <section>
          <h2 className="text-xl font-semibold mb-3 text-filliminal-yellow">7. Termination</h2>
          <p>We may terminate or suspend your account and access to the service immediately, without prior notice, for conduct that we believe violates these Terms or is harmful to other users, us, or third parties, or for any other reason.</p>
        </section>

        <section>
          <h2 className="text-xl font-semibold mb-3 text-filliminal-yellow">8. Limitation of Liability</h2>
          <p>In no event shall Filliminal, its directors, employees, partners, agents, suppliers, or affiliates, be liable for any indirect, incidental, special, consequential or punitive damages, including without limitation, loss of profits, data, use, goodwill, or other intangible losses.</p>
        </section>

        <section>
          <h2 className="text-xl font-semibold mb-3 text-filliminal-yellow">9. Changes to Terms</h2>
          <p>We reserve the right to modify or replace these Terms at any time. If a revision is material, we will provide at least 30 days' notice prior to any new terms taking effect.</p>
        </section>

        <section>
          <h2 className="text-xl font-semibold mb-3 text-filliminal-yellow">10. Contact Us</h2>
          <p>If you have any questions about these Terms, please contact us at terms@filliminal.com</p>
        </section>
      </div>
    </div>
  );
}
