import React from 'react';
import { Link } from 'react-router-dom';
import { MapPin, Users, Clock } from 'lucide-react';
import type { Venue } from '../../types/venue';

interface VenueCardProps {
  venue: Venue;
  layout?: 'grid' | 'list';
}

export function VenueCard({ venue, layout = 'grid' }: VenueCardProps) {
  return (
    <Link
      to={`/venues/${venue.id}`}
      className={`
        block bg-filliminal-dark-lighter rounded-lg overflow-hidden border border-filliminal-yellow/20
        hover:border-filliminal-yellow/40 transition-colors duration-200
        ${layout === 'list' ? 'flex gap-6' : ''}
      `}
    >
      <div className={layout === 'list' ? 'w-64 flex-shrink-0' : ''}>
        <img
          src={venue.photos[0]?.url || '/placeholder-venue.jpg'}
          alt={venue.name}
          className="w-full h-48 object-cover"
        />
      </div>
      
      <div className="p-4">
        <div className="flex items-start justify-between">
          <div>
            <h3 className="text-lg font-semibold text-filliminal-yellow mb-1">
              {venue.name}
            </h3>
            <div className="flex items-center text-filliminal-yellow/60 text-sm mb-2">
              <MapPin className="w-4 h-4 mr-1" />
              <span>{venue.location.city}, {venue.location.state}</span>
            </div>
          </div>
          <div className="flex items-center text-filliminal-yellow">
            <Clock className="w-4 h-4 mr-1" />
            <span>{venue.baseRate.amount}/{venue.baseRate.type}</span>
          </div>
        </div>
        
        <div className="flex items-center text-filliminal-yellow/60 text-sm mb-4">
          <Users className="w-4 h-4 mr-1" />
          <span>Minimum {venue.availability.minimumDuration} hours</span>
        </div>
        
        <div className="flex flex-wrap gap-2">
          {venue.amenities.slice(0, 3).map((amenity) => (
            <span
              key={amenity.id}
              className="px-2 py-1 text-xs rounded-full bg-filliminal-yellow/10 text-filliminal-yellow"
            >
              {amenity.name}
            </span>
          ))}
        </div>
      </div>
    </Link>
  );
}
