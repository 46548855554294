import React from 'react';
import { useFormContext } from 'react-hook-form';
import type { Venue } from '../../../../../types/venue';
import { Input } from '../../../../../components/ui/Input';
import { Select } from '../../../../../components/ui/Select';
import { Typography } from '../../../../../components/ui/Typography';

const countries = [
  { value: 'CA', label: 'Canada' },
  { value: 'US', label: 'United States' },
];

export function LocationInfo() {
  const { register, formState: { errors } } = useFormContext<Venue>();

  return (
    <div className="space-y-6">
      <Typography variant="h3" className="text-filliminal-yellow mb-4">
        Location Information
      </Typography>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <div>
          <label className="block text-filliminal-yellow mb-2">Street Address</label>
          <Input
            {...register('location.address', { required: 'Address is required' })}
            placeholder="Enter street address"
            error={errors.location?.address?.message}
          />
        </div>

        <div>
          <label className="block text-filliminal-yellow mb-2">City</label>
          <Input
            {...register('location.city', { required: 'City is required' })}
            placeholder="Enter city"
            error={errors.location?.city?.message}
          />
        </div>

        <div>
          <label className="block text-filliminal-yellow mb-2">State/Province</label>
          <Input
            {...register('location.state', { required: 'State/Province is required' })}
            placeholder="Enter state or province"
            error={errors.location?.state?.message}
          />
        </div>

        <div>
          <label className="block text-filliminal-yellow mb-2">Postal Code</label>
          <Input
            {...register('location.zipCode', { 
              required: 'Postal code is required',
              pattern: {
                value: /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d|^\d{5}(-\d{4})?$/,
                message: 'Invalid postal code format'
              }
            })}
            placeholder="Enter postal code"
            error={errors.location?.zipCode?.message}
          />
        </div>

        <div>
          <label className="block text-filliminal-yellow mb-2">Country</label>
          <Select
            {...register('location.country', { required: 'Country is required' })}
            error={errors.location?.country?.message}
          >
            <option value="">Select a country</option>
            {countries.map(country => (
              <option key={country.value} value={country.value}>
                {country.label}
              </option>
            ))}
          </Select>
        </div>
      </div>

      <div className="mt-8">
        <Typography variant="h4" className="text-filliminal-yellow mb-4">
          Additional Location Details
        </Typography>
        <div className="space-y-4">
          <div>
            <label className="block text-filliminal-yellow mb-2">Parking Instructions</label>
            <Input
              {...register('parkingInstructions')}
              placeholder="Describe parking options and instructions"
            />
          </div>
          <div>
            <label className="block text-filliminal-yellow mb-2">Access Instructions</label>
            <Input
              {...register('accessInstructions')}
              placeholder="How should guests access the venue?"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
