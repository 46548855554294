import { 
  collection, 
  query, 
  where, 
  getDocs, 
  doc, 
  getDoc,
  DocumentSnapshot,
  QuerySnapshot,
  limit,
  orderBy,
  startAfter,
  enableNetwork,
  disableNetwork,
  Timestamp
} from 'firebase/firestore';
import { db } from '../firebase';
import { User } from '../types';

// Cache durations
const CACHE_DURATION = 5 * 60 * 1000; // 5 minutes
const STALE_WHILE_REVALIDATE = 30 * 1000; // 30 seconds

// Query batch sizes
const BATCH_SIZE = 10;

interface CacheItem<T> {
  data: T;
  timestamp: number;
  expiresAt: number;
}

class DashboardService {
  private static instance: DashboardService;
  private cache: Map<string, CacheItem<any>> = new Map();
  private prefetchQueue: Set<string> = new Set();
  private networkEnabled = true;
  private queryInProgress = false;

  private constructor() {
    // Initialize network state
    window.addEventListener('online', this.handleOnline);
    window.addEventListener('offline', this.handleOffline);
  }

  private handleOnline = async () => {
    try {
      await enableNetwork(db);
      this.networkEnabled = true;
      this.processPrefetchQueue();
    } catch (error) {
      console.error('Error enabling network:', error);
    }
  };

  private handleOffline = async () => {
    try {
      await disableNetwork(db);
      this.networkEnabled = false;
    } catch (error) {
      console.error('Error disabling network:', error);
    }
  };

  static getInstance(): DashboardService {
    if (!DashboardService.instance) {
      DashboardService.instance = new DashboardService();
    }
    return DashboardService.instance;
  }

  // Dashboard data management
  async getDashboardData(userId: string) {
    const cacheKey = `dashboard:${userId}`;
    const cachedData = this.getCacheItem(cacheKey);
    
    if (cachedData) {
      return cachedData;
    }

    if (!this.networkEnabled) {
      throw new Error('Cannot fetch dashboard data while offline');
    }

    try {
      // Wait if another query is in progress
      while (this.queryInProgress) {
        await new Promise(resolve => setTimeout(resolve, 100));
      }

      this.queryInProgress = true;

      const [venues, bookings, reviews] = await Promise.all([
        this.getUserVenues(userId),
        this.getUserBookings(userId),
        this.getUserReviews(userId)
      ]);

      const dashboardData = {
        venues,
        bookings,
        reviews,
        stats: this.calculateStats(venues, bookings, reviews)
      };

      this.setCacheItem(cacheKey, dashboardData);
      return dashboardData;
    } catch (error) {
      console.error('Error fetching dashboard data:', error);
      throw error;
    } finally {
      this.queryInProgress = false;
    }
  }

  // Specific data fetchers with pagination
  private async getUserVenues(userId: string, lastDoc?: DocumentSnapshot) {
    const cacheKey = `venues:${userId}`;
    const cachedData = this.getCacheItem(cacheKey);
    
    if (cachedData) {
      return cachedData;
    }

    const baseQuery = query(
      collection(db, 'venues'),
      where('userId', '==', userId),
      orderBy('createdAt', 'desc'),
      limit(BATCH_SIZE)
    );

    const venuesQuery = lastDoc 
      ? query(baseQuery, startAfter(lastDoc))
      : baseQuery;

    const venues = await getDocs(venuesQuery);
    const venuesData = venues.docs.map(doc => ({
      id: doc.id,
      ...doc.data(),
      createdAt: this.formatTimestamp(doc.data().createdAt)
    }));

    this.setCacheItem(cacheKey, venuesData);
    return venuesData;
  }

  private async getUserBookings(userId: string, lastDoc?: DocumentSnapshot) {
    const cacheKey = `bookings:${userId}`;
    const cachedData = this.getCacheItem(cacheKey);
    
    if (cachedData) {
      return cachedData;
    }

    const baseQuery = query(
      collection(db, 'bookings'),
      where('userId', '==', userId),
      orderBy('createdAt', 'desc'),
      limit(BATCH_SIZE)
    );

    const bookingsQuery = lastDoc 
      ? query(baseQuery, startAfter(lastDoc))
      : baseQuery;

    const bookings = await getDocs(bookingsQuery);
    const bookingsData = bookings.docs.map(doc => ({
      id: doc.id,
      ...doc.data(),
      createdAt: this.formatTimestamp(doc.data().createdAt)
    }));

    this.setCacheItem(cacheKey, bookingsData);
    return bookingsData;
  }

  private async getUserReviews(userId: string, lastDoc?: DocumentSnapshot) {
    const cacheKey = `reviews:${userId}`;
    const cachedData = this.getCacheItem(cacheKey);
    
    if (cachedData) {
      return cachedData;
    }

    const baseQuery = query(
      collection(db, 'reviews'),
      where('userId', '==', userId),
      orderBy('createdAt', 'desc'),
      limit(BATCH_SIZE)
    );

    const reviewsQuery = lastDoc 
      ? query(baseQuery, startAfter(lastDoc))
      : baseQuery;

    const reviews = await getDocs(reviewsQuery);
    const reviewsData = reviews.docs.map(doc => ({
      id: doc.id,
      ...doc.data(),
      createdAt: this.formatTimestamp(doc.data().createdAt)
    }));

    this.setCacheItem(cacheKey, reviewsData);
    return reviewsData;
  }

  // Cache management
  private getCacheItem<T>(key: string): T | null {
    const item = this.cache.get(key);
    if (!item) return null;

    const now = Date.now();
    if (now > item.expiresAt + STALE_WHILE_REVALIDATE) {
      this.cache.delete(key);
      return null;
    }

    if (now > item.expiresAt) {
      // Data is stale but still usable, trigger background refresh
      this.prefetchQueue.add(key);
      this.processPrefetchQueue();
    }

    return item.data;
  }

  private setCacheItem<T>(key: string, data: T) {
    const now = Date.now();
    this.cache.set(key, {
      data,
      timestamp: now,
      expiresAt: now + CACHE_DURATION
    });
  }

  private async processPrefetchQueue() {
    if (!this.networkEnabled || this.queryInProgress) return;

    for (const key of this.prefetchQueue) {
      const [type, userId] = key.split(':');
      try {
        this.queryInProgress = true;
        switch (type) {
          case 'venues':
            await this.getUserVenues(userId);
            break;
          case 'bookings':
            await this.getUserBookings(userId);
            break;
          case 'reviews':
            await this.getUserReviews(userId);
            break;
        }
      } catch (error) {
        console.error(`Error prefetching ${key}:`, error);
      } finally {
        this.queryInProgress = false;
        this.prefetchQueue.delete(key);
      }
    }
  }

  private formatTimestamp(timestamp: Timestamp | null) {
    if (!timestamp) return null;
    return timestamp.toDate().toISOString();
  }

  private calculateStats(venues: any[], bookings: any[], reviews: any[]) {
    // Add your stats calculation logic here
    return {
      totalVenues: venues.length,
      totalBookings: bookings.length,
      totalReviews: reviews.length,
      // Add more stats as needed
    };
  }

  // Cleanup
  destroy() {
    window.removeEventListener('online', this.handleOnline);
    window.removeEventListener('offline', this.handleOffline);
    this.cache.clear();
    this.prefetchQueue.clear();
  }
}

export const dashboardService = DashboardService.getInstance();
