import React from 'react';
import { Link } from 'react-router-dom';
import { Mail, ArrowRight } from 'lucide-react';
import { Typography } from '../../components/ui/Typography';
import { Button } from '../../components/ui/Button';

interface ResetSuccessProps {
  email: string;
}

export function ResetSuccess({ email }: ResetSuccessProps) {
  return (
    <div className="min-h-screen flex items-center justify-center px-4 bg-filliminal-dark">
      <div className="max-w-md w-full text-center space-y-6">
        <div className="mx-auto w-16 h-16 bg-filliminal-yellow/10 rounded-full flex items-center justify-center">
          <Mail className="w-8 h-8 text-filliminal-yellow" />
        </div>

        <div className="space-y-2">
          <Typography variant="h1" className="text-4xl">Check Your Email</Typography>
          <Typography variant="body" className="text-filliminal-yellow/70">
            We've sent password reset instructions to:
          </Typography>
          <Typography variant="body" className="font-medium text-filliminal-yellow">
            {email}
          </Typography>
        </div>

        <div className="bg-filliminal-dark-lighter border border-filliminal-yellow/20 rounded-lg p-6 space-y-4">
          <Typography variant="body" className="text-filliminal-yellow/90">
            Click the link in the email to reset your password. 
            The link will expire in 1 hour.
          </Typography>

          <div className="text-sm text-filliminal-yellow/70">
            Can't find the email? Check your spam folder or{' '}
            <button className="text-filliminal-yellow hover:underline">
              resend reset instructions
            </button>
          </div>
        </div>

        <Link to="/login">
          <Button variant="secondary" className="inline-flex items-center">
            Return to Login
            <ArrowRight className="ml-2 w-4 h-4" />
          </Button>
        </Link>
      </div>
    </div>
  );
}