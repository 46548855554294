export function DataDeletion() {
  return (
    <div className="container mx-auto px-4 py-8 max-w-4xl">
      <h1 className="text-3xl font-bold mb-6 text-filliminal-yellow">Data Deletion Instructions</h1>
      
      <div className="space-y-6 text-filliminal-light">
        <section>
          <h2 className="text-2xl font-semibold mb-3 text-filliminal-yellow">How to Delete Your Data</h2>
          <p>You have several options to request deletion of your personal data from Filliminal:</p>
        </section>

        <section>
          <h2 className="text-xl font-semibold mb-3 text-filliminal-yellow">Option 1: Through Your Account</h2>
          <ol className="list-decimal pl-6 space-y-2">
            <li>Log in to your Filliminal account</li>
            <li>Go to Account Settings</li>
            <li>Select "Delete Account"</li>
            <li>Confirm your decision</li>
          </ol>
        </section>

        <section>
          <h2 className="text-xl font-semibold mb-3 text-filliminal-yellow">Option 2: Contact Us</h2>
          <p>Send a data deletion request to:</p>
          <ul className="list-disc pl-6 space-y-2">
            <li>Email: privacy@filliminal.com</li>
            <li>Subject: Data Deletion Request</li>
            <li>Include your account email and any relevant details</li>
          </ul>
        </section>

        <section>
          <h2 className="text-xl font-semibold mb-3 text-filliminal-yellow">What We Delete</h2>
          <ul className="list-disc pl-6 space-y-2">
            <li>Account information</li>
            <li>Profile data</li>
            <li>Usage history</li>
            <li>Any associated content</li>
          </ul>
        </section>

        <section>
          <h2 className="text-xl font-semibold mb-3 text-filliminal-yellow">Processing Time</h2>
          <p>Data deletion requests are typically processed within 30 days. You will receive a confirmation email once the deletion is complete.</p>
        </section>

        <section>
          <h2 className="text-xl font-semibold mb-3 text-filliminal-yellow">Legal Requirements</h2>
          <p>Some information may be retained if required by law or for legitimate business purposes, such as audit logs or legal compliance.</p>
        </section>
      </div>
    </div>
  );
}
