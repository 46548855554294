import { useAuth } from '../../../hooks/useAuth';
import { useFirestoreDocument } from '../../../hooks/useFirestoreDocument';
import { Typography } from '../../../components/ui/Typography';
import { Card } from '../../../components/ui/Card';
import { Button } from '../../../components/ui/Button';
import type { Venue } from '../../../types/venue';

export function SavedVenues() {
  const { user } = useAuth();
  const { data: savedVenues, loading, error } = useFirestoreDocument<{ venues: Venue[] }>(
    `users/${user?.uid}/saved`,
    'venues',
    { subscribe: true }
  );

  if (loading) {
    return (
      <div className="space-y-4">
        <div className="animate-pulse">
          <div className="h-8 bg-filliminal-yellow/20 rounded w-1/4 mb-6"></div>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
            {[1, 2, 3].map((i) => (
              <div key={i} className="h-64 bg-filliminal-yellow/10 rounded"></div>
            ))}
          </div>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <Card>
        <Card.Body>
          <Typography variant="body" className="text-filliminal-yellow/70">
            Error loading saved venues: {error.message}
          </Typography>
        </Card.Body>
      </Card>
    );
  }

  if (!savedVenues?.venues || savedVenues.venues.length === 0) {
    return (
      <Card>
        <Card.Body>
          <Typography variant="h3" className="mb-4">No Saved Venues</Typography>
          <Typography variant="body" className="text-filliminal-yellow/70 mb-6">
            You haven't saved any venues yet. Start exploring to find your perfect space!
          </Typography>
          <Button variant="primary" href="/explore">
            Explore Venues
          </Button>
        </Card.Body>
      </Card>
    );
  }

  return (
    <div className="space-y-6">
      <Typography variant="h2">Saved Venues</Typography>
      
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {savedVenues.venues.map((venue) => (
          <Card key={venue.id} className="hover:border-filliminal-yellow/30 transition-colors">
            <div className="aspect-video relative overflow-hidden rounded-t-lg">
              <img
                src={venue.photos[0]?.url || '/placeholder-venue.jpg'}
                alt={venue.name}
                className="object-cover w-full h-full"
              />
            </div>
            <Card.Body>
              <Typography variant="h4">{venue.name}</Typography>
              <Typography variant="body" className="text-filliminal-yellow/70">
                {venue.location.city}, {venue.location.state}
              </Typography>
              <Typography variant="small" className="text-filliminal-yellow/50">
                {venue.category}
              </Typography>
              
              <div className="mt-4 flex gap-4">
                <Button variant="primary" size="sm" href={`/venues/${venue.id}`}>
                  View Details
                </Button>
                <Button variant="secondary" size="sm" href={`/venues/${venue.id}/book`}>
                  Book Now
                </Button>
              </div>
            </Card.Body>
          </Card>
        ))}
      </div>
    </div>
  );
}