import React, { useState, useCallback, lazy, Suspense } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { Facebook } from 'lucide-react';
import { Typography } from '../../components/ui/Typography';
import { Button } from '../../components/ui/Button';
import { useAuth } from '../../hooks/useAuth';
import toast from 'react-hot-toast';

export function SignUp() {
  console.log('SignUp component rendered');
  const { signUpWithEmail, signInWithGoogle, signInWithFacebook } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  // Memoize handlers to prevent unnecessary re-renders
  const handleEmailSignUp = useCallback(async (e: React.FormEvent) => {
    e.preventDefault();
    if (!email || !password) {
      toast.error('Please fill in all fields');
      return;
    }

    try {
      setIsLoading(true);
      await signUpWithEmail({ email, password });
      toast.success('Account created successfully!');
      navigate('/dashboard');
    } catch (error: any) {
      console.error('Sign up failed:', error);
      toast.error(error.message || 'Failed to create account');
    } finally {
      setIsLoading(false);
    }
  }, [email, password, signUpWithEmail, navigate]);

  const handleSocialSignIn = useCallback(async (provider: 'google' | 'facebook') => {
    try {
      setIsLoading(true);
      const signInMethod = provider === 'google' ? signInWithGoogle : signInWithFacebook;
      await signInMethod();
      navigate('/dashboard');
    } catch (error) {
      console.error(`${provider} sign in failed:`, error);
      toast.error(`Failed to sign in with ${provider}`);
    } finally {
      setIsLoading(false);
    }
  }, [signInWithGoogle, signInWithFacebook, navigate]);

  // Lazy load social buttons
  const SocialButtons = lazy(() => Promise.resolve({
    default: () => (
      <div className="grid grid-cols-1 gap-3">
        <Button
          type="button"
          variant="outline"
          className="w-full flex items-center justify-center space-x-2 border-filliminal-yellow/20 hover:bg-filliminal-yellow/5"
          onClick={() => handleSocialSignIn('google')}
          disabled={isLoading}
        >
          <img src="/google.svg" alt="Google" className="w-5 h-5" />
          <span>Continue with Google</span>
        </Button>

        <Button
          type="button"
          variant="outline"
          className="w-full flex items-center justify-center space-x-2 border-filliminal-yellow/20 hover:bg-filliminal-yellow/5"
          onClick={() => handleSocialSignIn('facebook')}
          disabled={isLoading}
        >
          <Facebook className="w-5 h-5" />
          <span>Continue with Facebook</span>
        </Button>
      </div>
    )
  }));

  return (
    <div className="min-h-screen bg-filliminal-dark flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full space-y-8">
        <div className="text-center">
          <Typography variant="h1" className="text-4xl font-bold text-filliminal-yellow">
            Create Account
          </Typography>
          <Typography variant="body" className="mt-2 text-filliminal-yellow/70">
            Join our community of event hosts and guests
          </Typography>
        </div>

        <form onSubmit={handleEmailSignUp} className="mt-8 space-y-6">
          <div className="rounded-md shadow-sm -space-y-px">
            <div>
              <label htmlFor="email" className="sr-only">Email address</label>
              <input
                id="email"
                name="email"
                type="email"
                autoComplete="email"
                required
                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-filliminal-yellow/20 placeholder-filliminal-yellow/50 text-filliminal-yellow bg-transparent rounded-t-md focus:outline-none focus:ring-filliminal-yellow focus:border-filliminal-yellow focus:z-10 sm:text-sm"
                placeholder="Email address"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                disabled={isLoading}
              />
            </div>
            <div>
              <label htmlFor="password" className="sr-only">Password</label>
              <input
                id="password"
                name="password"
                type="password"
                autoComplete="new-password"
                required
                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-filliminal-yellow/20 placeholder-filliminal-yellow/50 text-filliminal-yellow bg-transparent rounded-b-md focus:outline-none focus:ring-filliminal-yellow focus:border-filliminal-yellow focus:z-10 sm:text-sm"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                disabled={isLoading}
              />
            </div>
          </div>

          <div>
            <Button
              type="submit"
              className="w-full"
              disabled={isLoading}
            >
              {isLoading ? 'Creating account...' : 'Sign up with email'}
            </Button>
          </div>
        </form>

        <div className="mt-6">
          <div className="relative">
            <div className="absolute inset-0 flex items-center">
              <div className="w-full border-t border-filliminal-yellow/20"></div>
            </div>
            <div className="relative flex justify-center text-sm">
              <span className="px-2 bg-filliminal-dark text-filliminal-yellow/70">Or continue with</span>
            </div>
          </div>

          <Suspense fallback={<div className="mt-6 text-center text-filliminal-yellow/70">Loading options...</div>}>
            <SocialButtons />
          </Suspense>
        </div>

        <p className="mt-2 text-center text-sm text-filliminal-yellow/70">
          Already have an account?{' '}
          <Link to="/login" className="font-medium text-filliminal-yellow hover:text-filliminal-yellow/90">
            Sign in
          </Link>
        </p>
      </div>
    </div>
  );
}