import React from 'react';
import { Link } from 'react-router-dom';
import { Facebook, Twitter, Instagram, Youtube } from 'lucide-react';
import { Typography } from '../ui/Typography';

export function Footer() {
  return (
    <footer className="border-t border-filliminal-yellow/20 mt-16">
      <div className="max-w-7xl mx-auto px-4 py-12">
        <div className="grid grid-cols-2 md:grid-cols-4 gap-8">
          <div>
            <Typography variant="h4" className="mb-4 text-filliminal-yellow">Company</Typography>
            <ul className="space-y-2">
              <li><Link to="/about" className="text-filliminal-yellow/70 hover:text-filliminal-yellow">About Us</Link></li>
              <li><Link to="/careers" className="text-filliminal-yellow/70 hover:text-filliminal-yellow">Careers</Link></li>
              <li><Link to="/press" className="text-filliminal-yellow/70 hover:text-filliminal-yellow">Press</Link></li>
              <li><Link to="/blog" className="text-filliminal-yellow/70 hover:text-filliminal-yellow">Blog</Link></li>
            </ul>
          </div>

          <div>
            <Typography variant="h4" className="mb-4 text-filliminal-yellow">Support</Typography>
            <ul className="space-y-2">
              <li><Link to="/help" className="text-filliminal-yellow/70 hover:text-filliminal-yellow">Help Center</Link></li>
              <li><Link to="/safety" className="text-filliminal-yellow/70 hover:text-filliminal-yellow">Safety</Link></li>
              <li><Link to="/cancellation" className="text-filliminal-yellow/70 hover:text-filliminal-yellow">Cancellation Options</Link></li>
              <li><Link to="/covid" className="text-filliminal-yellow/70 hover:text-filliminal-yellow">COVID-19 Response</Link></li>
            </ul>
          </div>

          <div>
            <Typography variant="h4" className="mb-4 text-filliminal-yellow">Hosting</Typography>
            <ul className="space-y-2">
              <li><Link to="/host" className="text-filliminal-yellow/70 hover:text-filliminal-yellow">List Your Space</Link></li>
              <li><Link to="/host/insurance" className="text-filliminal-yellow/70 hover:text-filliminal-yellow">Insurance</Link></li>
              <li><Link to="/host/guidelines" className="text-filliminal-yellow/70 hover:text-filliminal-yellow">Guidelines</Link></li>
              <li><Link to="/host/community" className="text-filliminal-yellow/70 hover:text-filliminal-yellow">Host Community</Link></li>
            </ul>
          </div>

          <div>
            <Typography variant="h4" className="mb-4 text-filliminal-yellow">Newsletter</Typography>
            <Typography variant="body" className="mb-4 text-filliminal-yellow/70">Stay updated with our latest features and venues</Typography>
            <form className="space-y-4">
              <input
                type="email"
                placeholder="Enter your email"
                className="input-base w-full px-4 py-2 rounded-lg"
              />
              <button className="btn-primary w-full px-4 py-2 rounded-lg">
                Subscribe
              </button>
            </form>
          </div>
        </div>

        <div className="mt-12 pt-8 border-t border-filliminal-yellow/20">
          <div className="flex flex-col md:flex-row justify-between items-center">
            <div className="flex items-center space-x-6 mb-4 md:mb-0">
              <Link to="/" className="text-filliminal-yellow/70 hover:text-filliminal-yellow">
                <Facebook className="w-6 h-6" />
              </Link>
              <Link to="/" className="text-filliminal-yellow/70 hover:text-filliminal-yellow">
                <Twitter className="w-6 h-6" />
              </Link>
              <Link to="/" className="text-filliminal-yellow/70 hover:text-filliminal-yellow">
                <Instagram className="w-6 h-6" />
              </Link>
              <Link to="/" className="text-filliminal-yellow/70 hover:text-filliminal-yellow">
                <Youtube className="w-6 h-6" />
              </Link>
            </div>
            <div className="flex flex-wrap justify-center gap-4 text-sm text-filliminal-yellow/70">
              <Link to="/privacy" className="hover:text-filliminal-yellow">Privacy Policy</Link>
              <Link to="/terms" className="hover:text-filliminal-yellow">Terms of Service</Link>
              <Link to="/sitemap" className="hover:text-filliminal-yellow">Sitemap</Link>
              <span>© 2024 Filliminal. All rights reserved.</span>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}