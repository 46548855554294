import React from 'react';
import { Star } from 'lucide-react';
import { Typography } from '../ui/Typography';

const testimonials = [
  {
    author: 'Sarah Johnson',
    role: 'Cafe Owner',
    image: 'https://images.unsplash.com/photo-1494790108377-be9c29b29330',
    content: 'Filliminal has transformed our quiet morning hours into a thriving creative space. The extra revenue has been incredible.',
    rating: 5
  },
  {
    author: 'Michael Chen',
    role: 'Photographer',
    image: 'https://images.unsplash.com/photo-1472099645785-5658abf4ff4e',
    content: 'Finding affordable studio space was always a challenge until I discovered Filliminal. Now I can book premium spaces at off-peak rates.',
    rating: 5
  },
  {
    author: 'Emma Davis',
    role: 'Event Planner',
    image: 'https://images.unsplash.com/photo-1438761681033-6461ffad8d80',
    content: 'The variety of unique venues available has allowed me to create unforgettable experiences for my clients.',
    rating: 5
  }
];

export function TestimonialsSection() {
  return (
    <section className="py-16">
      <div className="max-w-7xl mx-auto px-4">
        <div className="text-center mb-12">
          <Typography variant="h2" className="text-filliminal-yellow mb-4">Success Stories</Typography>
          <Typography variant="body" className="text-filliminal-yellow">Hear from our community of hosts and guests</Typography>
        </div>

        <div className="grid md:grid-cols-3 gap-8">
          {testimonials.map((testimonial) => (
            <div key={testimonial.author} className="bg-filliminal-dark-lighter p-6 rounded-lg border border-filliminal-yellow/20">
              <div className="flex items-center gap-4 mb-4">
                <img
                  src={testimonial.image}
                  alt={testimonial.author}
                  className="w-12 h-12 rounded-full object-cover"
                />
                <div>
                  <Typography variant="h4" className="text-lg text-filliminal-yellow">{testimonial.author}</Typography>
                  <Typography variant="small" className="text-filliminal-yellow/70">{testimonial.role}</Typography>
                </div>
              </div>
              <div className="flex mb-4">
                {Array.from({ length: testimonial.rating }).map((_, i) => (
                  <Star key={i} className="w-4 h-4 text-filliminal-yellow" fill="currentColor" />
                ))}
              </div>
              <Typography variant="body" className="text-filliminal-yellow/90">{testimonial.content}</Typography>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}