import React from 'react';
import { cn } from '../../lib/utils';

interface CheckboxProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  error?: string;
}

export function Checkbox({ label, error, className, ...props }: CheckboxProps) {
  return (
    <div className="flex items-start">
      <div className="flex items-center h-5">
        <input
          type="checkbox"
          className={cn(
            'h-4 w-4 rounded border-filliminal-yellow/30 bg-transparent',
            'text-filliminal-yellow focus:ring-filliminal-yellow/20',
            'checked:bg-filliminal-yellow checked:border-transparent',
            'transition-colors duration-200',
            error && 'border-red-500',
            className
          )}
          {...props}
        />
      </div>
      {label && (
        <div className="ml-3">
          <label
            htmlFor={props.id}
            className={cn(
              'text-sm font-medium text-filliminal-yellow/80',
              error && 'text-red-500'
            )}
          >
            {label}
          </label>
          {error && (
            <p className="mt-1 text-xs text-red-500">{error}</p>
          )}
        </div>
      )}
    </div>
  );
}
