import React from 'react';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import { format, startOfMonth, endOfMonth, eachDayOfInterval, isSameMonth, isSameDay, isToday } from 'date-fns';
import { cn } from '../../utils/cn';

interface CalendarProps {
  selectedDate?: Date;
  onDateSelect: (date: Date) => void;
  minDate?: Date;
  maxDate?: Date;
  disabledDates?: Date[];
  className?: string;
}

export function Calendar({
  selectedDate,
  onDateSelect,
  minDate = new Date(),
  maxDate,
  disabledDates = [],
  className
}: CalendarProps) {
  const [currentMonth, setCurrentMonth] = React.useState(new Date());
  
  const monthStart = startOfMonth(currentMonth);
  const monthEnd = endOfMonth(currentMonth);
  const monthDays = eachDayOfInterval({ start: monthStart, end: monthEnd });

  const previousMonth = () => {
    setCurrentMonth(prev => new Date(prev.getFullYear(), prev.getMonth() - 1));
  };

  const nextMonth = () => {
    setCurrentMonth(prev => new Date(prev.getFullYear(), prev.getMonth() + 1));
  };

  const isDateDisabled = (date: Date) => {
    if (minDate && date < minDate) return true;
    if (maxDate && date > maxDate) return true;
    return disabledDates.some(disabledDate => isSameDay(date, disabledDate));
  };

  return (
    <div className={cn("bg-filliminal-dark-lighter rounded-lg p-4", className)}>
      <div className="flex items-center justify-between mb-4">
        <button
          onClick={previousMonth}
          className="p-2 hover:bg-filliminal-yellow/10 rounded-full"
        >
          <ChevronLeft className="w-5 h-5 text-filliminal-yellow" />
        </button>
        <span className="text-filliminal-yellow font-medium">
          {format(currentMonth, 'MMMM yyyy')}
        </span>
        <button
          onClick={nextMonth}
          className="p-2 hover:bg-filliminal-yellow/10 rounded-full"
        >
          <ChevronRight className="w-5 h-5 text-filliminal-yellow" />
        </button>
      </div>

      <div className="grid grid-cols-7 gap-1 mb-2">
        {['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'].map(day => (
          <div
            key={day}
            className="text-center text-sm text-filliminal-yellow/70 font-medium"
          >
            {day}
          </div>
        ))}
      </div>

      <div className="grid grid-cols-7 gap-1">
        {monthDays.map(day => {
          const isDisabled = isDateDisabled(day);
          const isSelected = selectedDate && isSameDay(day, selectedDate);
          const isCurrentDay = isToday(day);

          return (
            <button
              key={day.toISOString()}
              onClick={() => !isDisabled && onDateSelect(day)}
              disabled={isDisabled}
              className={cn(
                "h-10 w-10 rounded-full flex items-center justify-center text-sm transition-colors",
                isDisabled && "opacity-50 cursor-not-allowed",
                !isDisabled && "hover:bg-filliminal-yellow/10",
                isSelected && "bg-filliminal-yellow text-filliminal-dark font-medium",
                isCurrentDay && !isSelected && "border border-filliminal-yellow/50",
                !isSelected && "text-filliminal-yellow"
              )}
            >
              {format(day, 'd')}
            </button>
          );
        })}
      </div>
    </div>
  );
}