import { 
  Home, Building2, Calendar, BarChart2, Wallet,
  BookMarked, Heart, Star, MessageSquare, Bell, Settings
} from 'lucide-react';

export const navigationConfig = {
  overview: {
    path: '',
    icon: Home,
    label: 'Overview',
  },
  host: [
    {
      path: 'venues',
      icon: Building2,
      label: 'Venues',
    },
    {
      path: 'bookings',
      icon: Calendar,
      label: 'Bookings',
    },
    {
      path: 'analytics',
      icon: BarChart2,
      label: 'Analytics',
    },
    {
      path: 'finance',
      icon: Wallet,
      label: 'Finance',
    },
  ],
  guest: [
    {
      path: 'my-bookings',
      icon: BookMarked,
      label: 'My Bookings',
    },
    {
      path: 'saved-venues',
      icon: Heart,
      label: 'Saved Venues',
    },
  ],
  shared: [
    {
      path: 'reviews',
      icon: Star,
      label: 'Reviews',
    },
    {
      path: 'messages',
      icon: MessageSquare,
      label: 'Messages',
    },
    {
      path: 'notifications',
      icon: Bell,
      label: 'Notifications',
    },
    {
      path: 'settings',
      icon: Settings,
      label: 'Settings',
    },
  ],
};