import React, { useState } from 'react';
import { Calendar as CalendarIcon } from 'lucide-react';
import { format } from 'date-fns';
import { Button } from '../ui/Button';
import { DateRangePicker } from '../calendar/DateRangePicker';
import { useClickOutside } from '../../hooks/useClickOutside';

interface DatePickerProps {
  startDate?: Date;
  endDate?: Date;
  onStartDateChange?: (date: Date) => void;
  onEndDateChange?: (date: Date) => void;
  minDate?: Date;
  maxDate?: Date;
  disabledDates?: Date[];
}

export function DatePicker({
  startDate,
  endDate,
  onStartDateChange,
  onEndDateChange,
  minDate = new Date(),
  maxDate,
  disabledDates = []
}: DatePickerProps) {
  const [isOpen, setIsOpen] = useState(false);
  const ref = useClickOutside(() => setIsOpen(false));

  const buttonText = startDate && endDate
    ? `${format(startDate, 'MMM d')} - ${format(endDate, 'MMM d')}`
    : 'Select dates';

  return (
    <div className="relative" ref={ref}>
      <Button
        variant="secondary"
        onClick={() => setIsOpen(!isOpen)}
        className="w-full justify-start bg-filliminal-dark-lighter text-filliminal-yellow border border-filliminal-yellow/20"
      >
        <CalendarIcon className="w-4 h-4 mr-2" />
        <span>{buttonText}</span>
      </Button>

      {isOpen && (
        <div className="absolute z-50 mt-2">
          <DateRangePicker
            startDate={startDate}
            endDate={endDate}
            onStartDateChange={(date) => onStartDateChange?.(date)}
            onEndDateChange={(date) => {
              onEndDateChange?.(date);
              setIsOpen(false);
            }}
            minDate={minDate}
            maxDate={maxDate}
            disabledDates={disabledDates}
          />
        </div>
      )}
    </div>
  );
}