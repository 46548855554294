import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { Card } from '../../../../../components/ui/Card';
import { Input } from '../../../../../components/ui/Input';
import { Select } from '../../../../../components/ui/Select';
import { Typography } from '../../../../../components/ui/Typography';
import { Button } from '../../../../../components/ui/Button';
import { Checkbox } from '../../../../../components/ui/Checkbox';
import { TextArea } from '../../../../../components/ui/TextArea';
import { Badge } from '../../../../../components/ui/Badge';
import { Modal } from '../../../../../components/ui/Modal';

const amenityCategories = {
  'Technical Equipment': [
    'Projector',
    'Sound System',
    'Microphones',
    'Lighting Equipment',
    'Video Recording',
    'Live Streaming Setup',
  ],
  'Furniture & Decor': [
    'Tables',
    'Chairs',
    'Stage',
    'Backdrop',
    'Lounge Furniture',
    'Dance Floor',
  ],
  'Facilities': [
    'Kitchen',
    'Restrooms',
    'Parking',
    'Green Room',
    'Storage Space',
    'Loading Dock',
  ],
  'Hospitality': [
    'Catering Services',
    'Bar Service',
    'Coffee & Tea',
    'Water Service',
    'Staff Support',
    'Security',
  ],
};

const pricingTypes = [
  { value: 'flat', label: 'Flat Rate' },
  { value: 'hourly', label: 'Per Hour' },
  { value: 'daily', label: 'Per Day' },
];

const cancellationPolicies = [
  {
    value: 'flexible',
    label: 'Flexible',
    description: 'Full refund up to 24 hours before the event',
    penalty: '0%',
  },
  {
    value: 'moderate',
    label: 'Moderate',
    description: 'Full refund up to 5 days before the event',
    penalty: '50%',
  },
  {
    value: 'strict',
    label: 'Strict',
    description: 'Full refund up to 7 days before the event',
    penalty: '100%',
  },
];

export function AmenitiesRules() {
  const { register, watch, setValue } = useFormContext();
  const [selectedAmenities, setSelectedAmenities] = useState<any[]>([]);
  const [showCustomAmenityModal, setShowCustomAmenityModal] = useState(false);
  const [customAmenity, setCustomAmenity] = useState({
    name: '',
    category: '',
    isPremium: false,
    price: '',
    pricingType: 'flat',
  });

  const handleAmenityToggle = (amenity: string, category: string) => {
    const exists = selectedAmenities.find(a => a.name === amenity);
    if (exists) {
      setSelectedAmenities(selectedAmenities.filter(a => a.name !== amenity));
    } else {
      setSelectedAmenities([...selectedAmenities, {
        name: amenity,
        category,
        isPremium: false,
        price: '',
        pricingType: 'flat',
      }]);
    }
  };

  const handleAddCustomAmenity = () => {
    if (customAmenity.name) {
      setSelectedAmenities([...selectedAmenities, { ...customAmenity }]);
      setCustomAmenity({
        name: '',
        category: '',
        isPremium: false,
        price: '',
        pricingType: 'flat',
      });
      setShowCustomAmenityModal(false);
    }
  };

  return (
    <div className="space-y-8">
      {/* Structured Amenities */}
      <Card className="p-6">
        <div className="flex justify-between items-center mb-6">
          <Typography variant="h3" className="text-filliminal-yellow">
            Amenities
          </Typography>
          <Button
            variant="secondary"
            size="sm"
            onClick={() => setShowCustomAmenityModal(true)}
          >
            Add Custom Amenity
          </Button>
        </div>

        {Object.entries(amenityCategories).map(([category, items]) => (
          <div key={category} className="mb-8">
            <Typography variant="h4" className="text-filliminal-yellow mb-4">
              {category}
            </Typography>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
              {items.map((amenity) => {
                const selected = selectedAmenities.find(a => a.name === amenity);
                return (
                  <div key={amenity} className="flex items-start space-x-3">
                    <Checkbox
                      checked={!!selected}
                      onChange={() => handleAmenityToggle(amenity, category)}
                    />
                    <div className="flex-1">
                      <div className="flex items-center space-x-2">
                        <span>{amenity}</span>
                        {selected?.isPremium && (
                          <Badge variant="premium">Premium</Badge>
                        )}
                      </div>
                      {selected && (
                        <div className="mt-2 space-y-2">
                          <Checkbox
                            label="Premium Feature"
                            checked={selected.isPremium}
                            onChange={(e) => {
                              const updated = selectedAmenities.map(a =>
                                a.name === amenity
                                  ? { ...a, isPremium: e.target.checked }
                                  : a
                              );
                              setSelectedAmenities(updated);
                            }}
                          />
                          {selected.isPremium && (
                            <div className="grid grid-cols-2 gap-2">
                              <Input
                                type="number"
                                placeholder="Price"
                                value={selected.price}
                                onChange={(e) => {
                                  const updated = selectedAmenities.map(a =>
                                    a.name === amenity
                                      ? { ...a, price: e.target.value }
                                      : a
                                  );
                                  setSelectedAmenities(updated);
                                }}
                              />
                              <Select
                                options={pricingTypes}
                                value={selected.pricingType}
                                onChange={(value) => {
                                  const updated = selectedAmenities.map(a =>
                                    a.name === amenity
                                      ? { ...a, pricingType: value }
                                      : a
                                  );
                                  setSelectedAmenities(updated);
                                }}
                              />
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        ))}
      </Card>

      {/* Rules Section */}
      <Card className="p-6">
        <Typography variant="h3" className="text-filliminal-yellow mb-6">
          House Rules
        </Typography>
        <div className="space-y-6">
          <TextArea
            label="General House Rules"
            placeholder="Enter your venue's general rules and guidelines..."
            {...register('rules.general')}
          />
          <TextArea
            label="Noise Restrictions"
            placeholder="Specify any noise restrictions or quiet hours..."
            {...register('rules.noise')}
          />
          <TextArea
            label="Cleaning Requirements"
            placeholder="Detail cleaning expectations and requirements..."
            {...register('rules.cleaning')}
          />
        </div>
      </Card>

      {/* Cancellation Policy */}
      <Card className="p-6">
        <Typography variant="h3" className="text-filliminal-yellow mb-6">
          Cancellation Policy
        </Typography>
        <div className="space-y-4">
          {cancellationPolicies.map((policy) => (
            <div key={policy.value} className="flex items-start space-x-3">
              <input
                type="radio"
                id={policy.value}
                value={policy.value}
                {...register('cancellationPolicy')}
                className="mt-1"
              />
              <label htmlFor={policy.value} className="flex-1">
                <Typography variant="subtitle" className="text-filliminal-yellow">
                  {policy.label}
                </Typography>
                <Typography variant="body" className="text-filliminal-yellow/70">
                  {policy.description}
                </Typography>
                <Typography variant="caption" className="text-filliminal-yellow/50">
                  Cancellation penalty: {policy.penalty}
                </Typography>
              </label>
            </div>
          ))}
        </div>
      </Card>

      {/* Custom Amenity Modal */}
      <Modal
        isOpen={showCustomAmenityModal}
        onClose={() => setShowCustomAmenityModal(false)}
        title="Add Custom Amenity"
      >
        <div className="space-y-4">
          <Input
            label="Amenity Name"
            value={customAmenity.name}
            onChange={(e) => setCustomAmenity({ ...customAmenity, name: e.target.value })}
          />
          <Input
            label="Category"
            value={customAmenity.category}
            onChange={(e) => setCustomAmenity({ ...customAmenity, category: e.target.value })}
          />
          <Checkbox
            label="Premium Feature"
            checked={customAmenity.isPremium}
            onChange={(e) => setCustomAmenity({ ...customAmenity, isPremium: e.target.checked })}
          />
          {customAmenity.isPremium && (
            <div className="grid grid-cols-2 gap-2">
              <Input
                type="number"
                label="Price"
                value={customAmenity.price}
                onChange={(e) => setCustomAmenity({ ...customAmenity, price: e.target.value })}
              />
              <Select
                label="Pricing Type"
                options={pricingTypes}
                value={customAmenity.pricingType}
                onChange={(value) => setCustomAmenity({ ...customAmenity, pricingType: value })}
              />
            </div>
          )}
          <div className="flex justify-end space-x-2">
            <Button
              variant="secondary"
              onClick={() => setShowCustomAmenityModal(false)}
            >
              Cancel
            </Button>
            <Button
              variant="primary"
              onClick={handleAddCustomAmenity}
            >
              Add Amenity
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
}
