import React from 'react';
import { Calendar } from './Calendar';
import { format } from 'date-fns';

interface DateRangePickerProps {
  startDate?: Date;
  endDate?: Date;
  onStartDateChange: (date: Date) => void;
  onEndDateChange: (date: Date) => void;
  minDate?: Date;
  maxDate?: Date;
  disabledDates?: Date[];
}

export function DateRangePicker({
  startDate,
  endDate,
  onStartDateChange,
  onEndDateChange,
  minDate,
  maxDate,
  disabledDates
}: DateRangePickerProps) {
  return (
    <div className="p-4 bg-filliminal-dark-lighter rounded-lg shadow-lg border border-filliminal-yellow/20">
      <div className="flex gap-4">
        <div>
          <div className="text-sm text-filliminal-yellow/70 mb-2">Start Date</div>
          <Calendar
            selectedDate={startDate}
            onDateSelect={(date) => {
              onStartDateChange(date);
              if (!endDate || date > endDate) {
                onEndDateChange(date);
              }
            }}
            minDate={minDate}
            maxDate={maxDate}
            disabledDates={disabledDates}
          />
        </div>
        <div>
          <div className="text-sm text-filliminal-yellow/70 mb-2">End Date</div>
          <Calendar
            selectedDate={endDate}
            onDateSelect={onEndDateChange}
            minDate={startDate || minDate}
            maxDate={maxDate}
            disabledDates={disabledDates}
          />
        </div>
      </div>
    </div>
  );
}