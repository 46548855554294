import { useContext } from 'react';
import { AuthContext } from '../../providers/AuthProvider';
import { 
  signInWithEmailAndPassword, 
  createUserWithEmailAndPassword,
  signInWithPopup,
  GoogleAuthProvider,
  FacebookAuthProvider,
  signOut as firebaseSignOut,
  sendPasswordResetEmail,
  setPersistence,
  browserLocalPersistence
} from 'firebase/auth';
import { auth, db } from '../firebase';
import { doc, setDoc, getDoc } from 'firebase/firestore';
import { User } from '../../types';

export function useAuth() {
  const { user, loading } = useContext(AuthContext);

  const signIn = async (email: string, password: string) => {
    console.log('Setting persistence to LOCAL');
    await setPersistence(auth, browserLocalPersistence);
    console.log('Attempting email/password sign in');
    const result = await signInWithEmailAndPassword(auth, email, password);
    console.log('Sign in successful, checking Firestore data');
    
    // Ensure user document exists
    const userDoc = await getDoc(doc(db, 'users', result.user.uid));
    if (!userDoc.exists()) {
      console.log('Creating new user document');
      const newUser = {
        uid: result.user.uid,
        email: result.user.email,
        role: 'unverified',
        needsVerification: true,
        profile: {},
        createdAt: new Date().toISOString(),
      };
      await setDoc(doc(db, 'users', result.user.uid), newUser);
    }
    
    return result;
  };

  const signUp = async (email: string, password: string) => {
    console.log('Setting persistence to LOCAL');
    await setPersistence(auth, browserLocalPersistence);
    console.log('Creating new user account');
    const userCredential = await createUserWithEmailAndPassword(auth, email, password);
    
    console.log('Creating user document in Firestore');
    const newUser = {
      uid: userCredential.user.uid,
      email: userCredential.user.email,
      role: 'unverified',
      needsVerification: true,
      profile: {},
      createdAt: new Date().toISOString(),
    };
    
    await setDoc(doc(db, 'users', userCredential.user.uid), newUser);
    return userCredential;
  };

  const signInWithGoogle = async () => {
    console.log('Setting persistence to LOCAL');
    await setPersistence(auth, browserLocalPersistence);
    console.log('Initiating Google sign in');
    const provider = new GoogleAuthProvider();
    const result = await signInWithPopup(auth, provider);
    
    console.log('Google sign in successful, checking Firestore data');
    // Check if user exists in Firestore
    const userDoc = await getDoc(doc(db, 'users', result.user.uid));
    if (!userDoc.exists()) {
      console.log('Creating new user document for Google user');
      // Create new user document
      const newUser = {
        uid: result.user.uid,
        email: result.user.email,
        role: 'unverified',
        needsVerification: true,
        profile: {
          fullName: result.user.displayName,
        },
        createdAt: new Date().toISOString(),
      };
      await setDoc(doc(db, 'users', result.user.uid), newUser);
    }
    
    return result;
  };

  const signInWithFacebook = async () => {
    console.log('Setting persistence to LOCAL');
    await setPersistence(auth, browserLocalPersistence);
    console.log('Initiating Facebook sign in');
    const provider = new FacebookAuthProvider();
    const result = await signInWithPopup(auth, provider);
    
    console.log('Facebook sign in successful, checking Firestore data');
    // Check if user exists in Firestore
    const userDoc = await getDoc(doc(db, 'users', result.user.uid));
    if (!userDoc.exists()) {
      console.log('Creating new user document for Facebook user');
      // Create new user document
      const newUser = {
        uid: result.user.uid,
        email: result.user.email,
        role: 'unverified',
        needsVerification: true,
        profile: {
          fullName: result.user.displayName,
        },
        createdAt: new Date().toISOString(),
      };
      await setDoc(doc(db, 'users', result.user.uid), newUser);
    }
    
    return result;
  };

  const signOut = async () => {
    console.log('Signing out');
    await firebaseSignOut(auth);
    console.log('Sign out complete');
  };

  const resetPassword = (email: string) => {
    console.log('Sending password reset email');
    return sendPasswordResetEmail(auth, email);
  };

  const updateUser = async (userData: Partial<User>) => {
    if (!user?.uid) throw new Error('No user found');
    console.log('Updating user data:', userData);
    await setDoc(doc(db, 'users', user.uid), userData, { merge: true });
  };

  return {
    user,
    loading,
    signIn,
    signUp,
    signOut,
    signInWithGoogle,
    signInWithFacebook,
    resetPassword,
    updateUser,
  };
}