import type { Venue } from '../../types/venue';

export const mockVenues: Venue[] = [
  {
    id: '1',
    ownerId: 'user1',
    name: 'Modern Downtown Cafe',
    description: 'A beautiful, light-filled cafe space perfect for pop-up events, photoshoots, and private gatherings.',
    category: 'cafe',
    searchTags: ['cafe', 'event-space', 'food-beverage', 'social'],
    location: {
      address: '123 Main St',
      city: 'Downtown',
      state: 'CA',
      zipCode: '90012',
      country: 'USA',
      coordinates: {
        latitude: 34.0522,
        longitude: -118.2437
      }
    },
    photos: [
      {
        id: 'photo1',
        url: 'https://images.unsplash.com/photo-1497366216548-37526070297c',
        caption: 'Main Space',
        isCover: true,
        order: 1
      },
      {
        id: 'photo2',
        url: 'https://images.unsplash.com/photo-1497366754035-f200968a6e72',
        caption: 'Kitchen Area',
        order: 2
      }
    ],
    baseRate: {
      id: 'rate1',
      name: 'Standard Rate',
      amount: 150,
      currency: 'USD',
      type: 'hourly',
      description: 'Standard hourly rate'
    },
    availability: {
      daysAvailable: [1, 2, 3, 4, 5],
      startTime: '09:00',
      endTime: '22:00',
      minimumNotice: 24,
      maximumNotice: 90,
      minimumDuration: 4
    },
    amenities: [
      {
        id: 'amenity1',
        name: 'WiFi',
        category: 'technology'
      },
      {
        id: 'amenity2',
        name: 'Kitchen',
        category: 'facilities'
      },
      {
        id: 'amenity3',
        name: 'Sound System',
        category: 'entertainment'
      }
    ],
    rules: [
      {
        id: 'rule1',
        title: 'No Smoking',
        description: 'Smoking is not allowed inside the venue',
        isRequired: true
      }
    ],
    bookingRequirements: [
      {
        id: 'req1',
        title: 'Insurance',
        description: 'Event insurance required for all bookings',
        isRequired: true
      }
    ],
    status: 'published',
    createdAt: new Date('2023-01-01'),
    updatedAt: new Date('2023-01-01')
  },
  {
    id: '2',
    ownerId: 'user2',
    name: 'Industrial Art Gallery',
    description: 'A spacious industrial gallery perfect for art exhibitions, fashion shows, and creative events.',
    category: 'gallery',
    searchTags: ['gallery', 'art-space', 'fashion', 'creative'],
    location: {
      address: '456 Gallery Row',
      city: 'Downtown',
      state: 'CA',
      zipCode: '90013',
      country: 'USA',
      coordinates: {
        latitude: 34.0461,
        longitude: -118.2427
      }
    },
    photos: [
      {
        id: 'photo3',
        url: 'https://images.unsplash.com/photo-1497366811353-6870744d04b2',
        caption: 'Main Gallery',
        isCover: true,
        order: 1
      },
      {
        id: 'photo4',
        url: 'https://images.unsplash.com/photo-1497366672149-e5e4b4d34eb3',
        caption: 'Side Gallery',
        order: 2
      }
    ],
    baseRate: {
      id: 'rate2',
      name: 'Standard Rate',
      amount: 200,
      currency: 'USD',
      type: 'hourly',
      description: 'Standard hourly rate'
    },
    availability: {
      daysAvailable: [1, 2, 3, 4, 5, 6, 7],
      startTime: '10:00',
      endTime: '23:00',
      minimumNotice: 48,
      maximumNotice: 120,
      minimumDuration: 6
    },
    amenities: [
      {
        id: 'amenity4',
        name: 'Track Lighting',
        category: 'lighting'
      },
      {
        id: 'amenity5',
        name: 'Display Walls',
        category: 'facilities'
      },
      {
        id: 'amenity6',
        name: 'Loading Dock',
        category: 'access'
      }
    ],
    rules: [
      {
        id: 'rule2',
        title: 'No Food/Drink',
        description: 'Food and beverages are not allowed in gallery spaces',
        isRequired: true
      }
    ],
    bookingRequirements: [
      {
        id: 'req2',
        title: 'Security Deposit',
        description: 'Refundable security deposit required',
        isRequired: true
      }
    ],
    status: 'published',
    createdAt: new Date('2023-01-02'),
    updatedAt: new Date('2023-01-02')
  }
];