import React, { useState } from 'react';
import { Search } from 'lucide-react';
import { useDebounce } from '../../hooks/useDebounce';

interface LocationSearchProps {
  onLocationSelect: (location: { address: string; lat: number; lng: number }) => void;
}

export function LocationSearch({ onLocationSelect }: LocationSearchProps) {
  const [searchTerm, setSearchTerm] = useState('');
  const [radius, setRadius] = useState('5');
  const debouncedSearch = useDebounce(searchTerm, 500);

  // Placeholder for Google Places API integration
  const searchLocation = async (query: string) => {
    // TODO: Implement Google Places API
    console.log('Searching for:', query, 'within', radius, 'miles');
    
    // Placeholder response
    onLocationSelect({
      address: query,
      lat: 0,
      lng: 0
    });
  };

  const handleSearch = (e: React.FormEvent) => {
    e.preventDefault();
    searchLocation(searchTerm);
  };

  return (
    <form onSubmit={handleSearch} className="flex gap-4 w-full">
      <div className="relative flex-grow">
        <input
          type="text"
          placeholder="Enter location..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="input-base w-full px-4 py-3 pl-12 rounded-lg"
        />
        <Search className="absolute left-4 top-3.5 h-5 w-5 text-filliminal-yellow/50" />
      </div>
      
      <select
        value={radius}
        onChange={(e) => setRadius(e.target.value)}
        className="input-base w-48 px-4 py-3 rounded-lg appearance-none bg-right pr-8"
      >
        <option value="5">Within 5 miles</option>
        <option value="10">Within 10 miles</option>
        <option value="25">Within 25 miles</option>
        <option value="50">Within 50 miles</option>
      </select>
    </form>
  );
}