import React from 'react';
import { Link } from 'react-router-dom';
import { Coffee, Camera, Users, Briefcase, Palette, Utensils } from 'lucide-react';
import { Typography } from '../ui/Typography';

const categories = [
  { 
    icon: Coffee, 
    name: 'Cafes', 
    count: '250+', 
    image: 'https://images.unsplash.com/photo-1501339847302-ac426a4a7cbb',
    type: 'cafe',
    accent: 'bg-filliminal-yellow'
  },
  { 
    icon: Camera, 
    name: 'Photo Studios', 
    count: '180+', 
    image: 'https://images.unsplash.com/photo-1516724562728-afc824a36e84',
    type: 'studio',
    accent: 'bg-filliminal-yellow'
  },
  { 
    icon: Users, 
    name: 'Event Spaces', 
    count: '320+', 
    image: 'https://images.unsplash.com/photo-1505236858219-8359eb29e329',
    type: 'event-space',
    accent: 'bg-filliminal-yellow'
  },
  { 
    icon: Briefcase, 
    name: 'Meeting Rooms', 
    count: '400+', 
    image: 'https://images.unsplash.com/photo-1497366811353-6870744d04b2',
    type: 'meeting-room',
    accent: 'bg-filliminal-yellow'
  },
  { 
    icon: Palette, 
    name: 'Art Galleries', 
    count: '150+', 
    image: 'https://images.unsplash.com/photo-1561488111-5d800fd56b8a',
    type: 'gallery',
    accent: 'bg-filliminal-yellow'
  },
  { 
    icon: Utensils, 
    name: 'Restaurants', 
    count: '280+', 
    image: 'https://images.unsplash.com/photo-1517248135467-4c7edcad34c4',
    type: 'restaurant',
    accent: 'bg-filliminal-yellow'
  },
];

export function CategorySection() {
  return (
    <section className="py-12">
      <div className="text-center mb-10">
        <Typography variant="h2" className="text-filliminal-yellow mb-4">Popular Categories</Typography>
        <Typography variant="body" className="text-filliminal-yellow">Discover unique spaces for every occasion</Typography>
      </div>
      
      <div className="grid grid-cols-2 md:grid-cols-3 gap-6">
        {categories.map((category) => (
          <Link 
            key={category.name} 
            to={`/search?type=${category.type}`}
            className="group cursor-pointer"
          >
            <div className="relative h-48 rounded-lg overflow-hidden mb-4">
              <img
                src={category.image}
                alt={category.name}
                className="w-full h-full object-cover group-hover:scale-105 transition-transform duration-200"
              />
              <div className="absolute inset-0 bg-gradient-to-t from-filliminal-dark/90 to-transparent" />
              <div className="absolute bottom-4 left-4">
                <div className={`${category.accent} p-2 rounded-full inline-block mb-2`}>
                  <category.icon className="w-6 h-6 text-filliminal-purple" />
                </div>
                <h3 className="text-lg font-semibold text-filliminal-yellow">{category.name}</h3>
                <p className="text-sm text-filliminal-yellow/90">{category.count} spaces</p>
              </div>
            </div>
          </Link>
        ))}
      </div>
    </section>
  );
}