import React from 'react';
import clsx from 'clsx';

interface TextAreaProps extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  error?: string;
}

export const TextArea = React.forwardRef<HTMLTextAreaElement, TextAreaProps>(
  ({ className, error, ...props }, ref) => {
    return (
      <div className="w-full">
        <textarea
          className={clsx(
            'w-full px-4 py-2 bg-filliminal-dark border rounded-lg focus:outline-none focus:ring-2 focus:ring-filliminal-yellow/50',
            error ? 'border-red-500' : 'border-filliminal-yellow/20',
            'text-filliminal-yellow placeholder-filliminal-yellow/50',
            className
          )}
          ref={ref}
          {...props}
        />
        {error && (
          <p className="mt-1 text-sm text-red-500">{error}</p>
        )}
      </div>
    );
  }
);

export const Textarea = TextArea;
TextArea.displayName = 'TextArea';
