import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { Toaster } from 'react-hot-toast';
import { Header } from './components/layout/Header';
import { Home } from './pages/Home';
import { Search } from './pages/Search';
import { VenueListing } from './pages/VenueListing';
import { SignUp } from './pages/SignUp/index';
import { EmailVerification } from './pages/SignUp/EmailVerification';
import { PasswordCreation } from './pages/SignUp/PasswordCreation';
import { VerificationSuccess } from './pages/SignUp/VerificationSuccess';
import { Login } from './pages/Login';
import { ForgotPassword } from './pages/ForgotPassword';
import { ResetSuccess } from './pages/ForgotPassword/ResetSuccess';
import { Dashboard } from './pages/Dashboard';
import { ListSpace } from './pages/ListSpace';
import { HostActivation } from './pages/ListSpace/HostActivation';
import { HostLanding } from './pages/ListSpace/HostLanding';
import { UnderConstruction } from './components/UnderConstruction';
import { EventBuilder } from './pages/EventBuilder';
import { HirePros } from './pages/HirePros';
import { AuthProvider } from './providers/AuthProvider';
import { FacebookSDK } from './components/auth/FacebookSDK';
import { PrivacyPolicy } from './pages/PrivacyPolicy';
import { DataDeletion } from './pages/DataDeletion';
import { TermsOfService } from './pages/TermsOfService';
import { Verification } from './pages/Verification';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

export default function App() {
  return (
    <Router>
      <QueryClientProvider client={queryClient}>
        <AuthProvider>
          <div className="min-h-screen bg-filliminal-dark pt-16">
            <FacebookSDK />
            <Header />
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/search" element={<Search />} />
              <Route path="/venues/:id" element={<VenueListing />} />
              <Route path="/event-builder" element={<EventBuilder />} />
              <Route path="/hire-pros" element={<HirePros />} />
              
              <Route path="/signup/*" element={<SignUp />}>
                <Route path="verification" element={<EmailVerification />} />
                <Route path="password" element={<PasswordCreation />} />
                <Route path="success" element={<VerificationSuccess />} />
              </Route>
              
              <Route path="/login" element={<Login />} />
              
              <Route path="/forgot-password/*" element={<ForgotPassword />}>
                <Route path="success" element={<ResetSuccess />} />
              </Route>
              
              <Route path="/verification" element={<Verification />} />
              <Route path="/dashboard/*" element={<Dashboard />} />
              
              <Route path="/list-space/*" element={<ListSpace />}>
                <Route index element={<HostLanding />} />
                <Route path="activation" element={<HostActivation />} />
              </Route>
              
              <Route path="/privacy-policy" element={<PrivacyPolicy />} />
              <Route path="/data-deletion" element={<DataDeletion />} />
              <Route path="/terms-of-service" element={<TermsOfService />} />
            </Routes>
            <Toaster 
              position="top-right"
              toastOptions={{
                style: {
                  background: '#1F1F1F',
                  color: '#F5B43C',
                  border: '1px solid rgba(245, 180, 60, 0.2)',
                },
              }}
            />
          </div>
        </AuthProvider>
      </QueryClientProvider>
    </Router>
  );
}