import React from 'react';
import { Link } from 'react-router-dom';
import { Wallet, CreditCard, FileText, Building } from 'lucide-react';
import { Typography } from '../../../../components/ui/Typography';
import { Card } from '../../../../components/ui/Card';
import { Button } from '../../../../components/ui/Button';

export function FinancialOverview() {
  return (
    <div className="space-y-6">
      <Typography variant="h2" className="text-filliminal-yellow">Financial Overview</Typography>

      <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
        <Card className="bg-filliminal-dark-lighter border border-filliminal-yellow/20">
          <Card.Body>
            <Link to="earnings" className="flex items-center gap-3">
              <Wallet className="w-5 h-5 text-filliminal-yellow" />
              <span className="text-filliminal-yellow">Earnings Overview</span>
            </Link>
          </Card.Body>
        </Card>

        <Card className="bg-filliminal-dark-lighter border border-filliminal-yellow/20">
          <Card.Body>
            <Link to="payouts" className="flex items-center gap-3">
              <CreditCard className="w-5 h-5 text-filliminal-yellow" />
              <span className="text-filliminal-yellow">Payout History</span>
            </Link>
          </Card.Body>
        </Card>

        <Card className="bg-filliminal-dark-lighter border border-filliminal-yellow/20">
          <Card.Body>
            <Link to="tax-documents" className="flex items-center gap-3">
              <FileText className="w-5 h-5 text-filliminal-yellow" />
              <span className="text-filliminal-yellow">Tax Documents</span>
            </Link>
          </Card.Body>
        </Card>

        <Card className="bg-filliminal-dark-lighter border border-filliminal-yellow/20">
          <Card.Body>
            <Link to="banking" className="flex items-center gap-3">
              <Building className="w-5 h-5 text-filliminal-yellow" />
              <span className="text-filliminal-yellow">Banking Information</span>
            </Link>
          </Card.Body>
        </Card>
      </div>

      <Card className="bg-filliminal-dark-lighter border border-filliminal-yellow/20">
        <Card.Body>
          <div className="flex justify-between items-center mb-6">
            <Typography variant="h3">Recent Transactions</Typography>
            <Button variant="secondary" size="sm">View All</Button>
          </div>

          <div className="space-y-4">
            {[1, 2, 3].map((transaction) => (
              <div key={transaction} className="flex items-center justify-between p-4 border border-filliminal-yellow/10 rounded-lg">
                <div>
                  <Typography variant="body" className="font-medium">Booking Payment</Typography>
                  <Typography variant="small" className="text-filliminal-yellow/70">
                    March 15, 2024
                  </Typography>
                </div>
                <Typography variant="body" className="text-green-500">+$240.00</Typography>
              </div>
            ))}
          </div>
        </Card.Body>
      </Card>
    </div>
  );
}