import React from 'react';
import { HeroSection } from '../components/home/HeroSection';
import { CategorySection } from '../components/home/CategorySection';
import { HowItWorksSection } from '../components/home/HowItWorksSection';
import { TestimonialsSection } from '../components/home/TestimonialsSection';
import { Footer } from '../components/layout/Footer';

console.log('Home component loaded');

export function Home() {
  console.log('Home component rendering');
  
  return (
    <div className="min-h-screen">
      <div className="max-w-7xl mx-auto px-4 space-y-12 mb-12">
        <HeroSection />
        <CategorySection />
        <HowItWorksSection />
        <TestimonialsSection />
      </div>
      <Footer />
    </div>
  );
}