import React from 'react';
import clsx from 'clsx';

interface Option {
  label: string;
  value: string;
}

interface SelectProps extends Omit<React.SelectHTMLAttributes<HTMLSelectElement>, 'multiple'> {
  options: Option[];
  error?: string;
  multiple?: boolean;
}

export const Select = React.forwardRef<HTMLSelectElement, SelectProps>(
  ({ className, error, options, multiple, ...props }, ref) => {
    return (
      <div className="w-full">
        <select
          className={clsx(
            'w-full px-4 py-2 bg-filliminal-dark border rounded-lg focus:outline-none focus:ring-2 focus:ring-filliminal-yellow/50',
            error ? 'border-red-500' : 'border-filliminal-yellow/20',
            'text-filliminal-yellow',
            className
          )}
          ref={ref}
          multiple={multiple}
          {...props}
        >
          {options.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
        {error && (
          <p className="mt-1 text-sm text-red-500">{error}</p>
        )}
      </div>
    );
  }
);
