import React from 'react';
import { Search, Calendar, Key, Star } from 'lucide-react';
import { Typography } from '../ui/Typography';
import { Button } from '../ui/Button';

const steps = [
  {
    icon: Search,
    title: 'Find Your Space',
    description: 'Search through unique venues available during off-peak hours'
  },
  {
    icon: Calendar,
    title: 'Book Your Time',
    description: 'Select your preferred date and time slot at special rates'
  },
  {
    icon: Key,
    title: 'Access & Enjoy',
    description: 'Get instant access to your space and start creating'
  },
  {
    icon: Star,
    title: 'Share Experience',
    description: 'Rate and review to help our community grow'
  }
];

export function HowItWorksSection() {
  return (
    <section className="py-16 bg-filliminal-dark-lighter rounded-xl border border-filliminal-yellow/20">
      <div className="max-w-7xl mx-auto px-4">
        <div className="text-center mb-12">
          <Typography variant="h2" className="text-filliminal-yellow mb-4">How It Works</Typography>
          <Typography variant="body" className="text-filliminal-yellow/70">Simple steps to find and book your perfect space</Typography>
        </div>

        <div className="grid md:grid-cols-4 gap-8 mb-12">
          {steps.map((step, index) => (
            <div key={step.title} className="text-center">
              <div className="relative mb-6">
                <div className="w-16 h-16 mx-auto bg-filliminal-yellow rounded-full flex items-center justify-center">
                  <step.icon className="w-8 h-8 text-filliminal-dark" />
                </div>
                {index < steps.length - 1 && (
                  <div className="hidden md:block absolute top-8 left-[60%] w-full h-0.5 bg-filliminal-yellow/20" />
                )}
              </div>
              <Typography variant="h4" className="text-filliminal-yellow mb-2">{step.title}</Typography>
              <Typography variant="body" className="text-filliminal-yellow/70">{step.description}</Typography>
            </div>
          ))}
        </div>

        <div className="text-center">
          <Button variant="primary" size="lg">Start Exploring</Button>
        </div>
      </div>
    </section>
  );
}