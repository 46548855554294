import { auth } from '../firebase';
import { User as FirebaseUser } from 'firebase/auth';

class TokenService {
  private isRefreshing = false;
  private readonly MAX_RETRIES = 3;
  private retryCount = 0;

  async getToken(forceRefresh = false): Promise<string | null> {
    try {
      if (!auth.currentUser) {
        return null;
      }

      if (this.isRefreshing) {
        console.log('Token refresh already in progress, waiting...');
        await new Promise(resolve => setTimeout(resolve, 1000));
        return this.getToken(forceRefresh);
      }

      this.isRefreshing = true;
      const token = await auth.currentUser.getIdToken(forceRefresh);
      this.retryCount = 0; // Reset retry count on success
      return token;
    } catch (error) {
      console.error('Error getting token:', error);
      
      if (this.retryCount < this.MAX_RETRIES) {
        this.retryCount++;
        const delay = Math.pow(2, this.retryCount) * 1000;
        await new Promise(resolve => setTimeout(resolve, delay));
        return this.getToken(forceRefresh);
      }
      
      return null;
    } finally {
      this.isRefreshing = false;
    }
  }
}

export const tokenService = new TokenService();

// Listen for auth state changes
auth.onAuthStateChanged((user: FirebaseUser | null) => {
  if (user) {
    tokenService.getToken(true).catch(console.error);
  }
});
