import React, { Suspense } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { useAuth } from '../../lib/hooks/useAuth';
import { useDashboard } from '../../lib/hooks/useDashboard';
import { Button } from '../../components/ui/Button';
import { Spinner } from '../../components/ui/Spinner';
import { DashboardLayout } from './components/DashboardLayout';
import { Typography } from '../../components/ui/Typography';

// Lazy load dashboard components
import { DashboardHome } from './Home';
import { VenueManagement } from './Host/VenueManagement';
import { AddVenueForm } from './Host/AddVenue';
import { BookingManagement } from './Host/BookingManagement';
import { PerformanceMetrics } from './Host/Analytics';
import { FinancialOverview } from './Host/Finance';
import { SavedVenues } from './Guest/SavedVenues';
import { Settings } from './Shared/Settings';
import { Reviews } from './Shared/Reviews';
import { Messages } from './Shared/Messages';
import { Notifications } from './Shared/Notifications';

// Temporary component until BookingHistory is implemented
function BookingHistory() {
  return (
    <div>
      <Typography variant="h2" className="text-filliminal-yellow">Booking History</Typography>
      <p className="text-filliminal-yellow/70">Coming soon...</p>
    </div>
  );
}

function LoadingFallback() {
  return (
    <div className="flex items-center justify-center min-h-screen">
      <Spinner size="lg" />
    </div>
  );
}

function ErrorBoundary({ error }: { error: Error }) {
  return (
    <div className="flex flex-col items-center justify-center min-h-screen p-4">
      <h2 className="text-2xl font-bold text-filliminal-yellow mb-4">
        Something went wrong
      </h2>
      <p className="text-filliminal-yellow/70 mb-6 text-center">
        {error.message}
      </p>
      <Button onClick={() => window.location.reload()}>
        Try Again
      </Button>
    </div>
  );
}

export function Dashboard() {
  const { user, isLoading: authLoading } = useAuth();
  const { 
    isLoading: dashboardLoading, 
    error, 
    data: dashboardData,
    isStale,
    refresh 
  } = useDashboard();

  if (authLoading || dashboardLoading) {
    return <LoadingFallback />;
  }

  if (error) {
    return <ErrorBoundary error={error} />;
  }

  if (!user) {
    return <Navigate to="/login" replace />;
  }

  if (user.role === 'unverified' || user.needsVerification) {
    return (
      <div className="min-h-screen bg-filliminal-dark flex items-center justify-center">
        <div className="max-w-md w-full mx-auto p-6 bg-filliminal-dark-lighter rounded-lg border border-filliminal-yellow/20">
          <Typography variant="h2" className="text-filliminal-yellow mb-4">
            Verification Required
          </Typography>
          <Typography variant="body" className="text-filliminal-yellow/70 mb-6">
            Please complete your profile verification to access the dashboard. This helps us maintain
            a secure and trusted community.
          </Typography>
          <Navigate to="/verification" replace />
        </div>
      </div>
    );
  }

  return (
    <Suspense fallback={<LoadingFallback />}>
      <Routes>
        <Route element={<DashboardLayout isStale={isStale} onRefresh={refresh} />}>
          <Route 
            index 
            element={
              <DashboardHome 
                data={dashboardData}
                isStale={isStale}
                onRefresh={refresh}
              />
            } 
          />
          <Route path="venues" element={<VenueManagement />} />
          <Route path="venues/new" element={<AddVenueForm />} />
          <Route path="bookings" element={<BookingManagement />} />
          <Route path="analytics" element={<PerformanceMetrics />} />
          <Route path="finance" element={<FinancialOverview />} />
          <Route path="my-bookings" element={<BookingHistory />} />
          <Route path="saved-venues" element={<SavedVenues />} />
          <Route path="settings" element={<Settings />} />
          <Route path="reviews" element={<Reviews />} />
          <Route path="messages" element={<Messages />} />
          <Route path="notifications" element={<Notifications />} />
        </Route>
      </Routes>
    </Suspense>
  );
}