import { useFirestoreDocument } from './useFirestoreDocument';
import type { Venue } from '../types/venue';

export function useVenueData(venueId: string, subscribe = false) {
  return useFirestoreDocument<Venue>(
    'venues',
    venueId,
    { subscribe }
  );
}
