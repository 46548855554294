import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../lib/hooks/useAuth';
import { Button } from '../components/ui/Button';
import { Input } from '../components/ui/Input';
import { Label } from '../components/ui/Label';
import { toast } from 'sonner';
import { doc, setDoc } from 'firebase/firestore';
import { db } from '../lib/firebase';

export function Verification() {
  const { user } = useAuth();
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formData, setFormData] = useState({
    fullName: user?.profile?.fullName || '',
    phoneNumber: user?.profile?.phoneNumber || '',
    address: user?.profile?.address || '',
  });

  useEffect(() => {
    if (!user) {
      navigate('/login', { replace: true });
    } else if (!user.needsVerification) {
      navigate('/dashboard', { replace: true });
    }
  }, [user, navigate]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      if (!user?.uid) throw new Error('No user found');

      // Update user document in Firestore
      await setDoc(doc(db, 'users', user.uid), {
        ...user,
        role: 'verified',
        needsVerification: false,
        profile: {
          ...user.profile,
          ...formData,
        },
      }, { merge: true });

      toast.success('Profile verified successfully!');
      navigate('/dashboard', { replace: true });
    } catch (error) {
      console.error('Verification error:', error);
      toast.error('Failed to verify profile. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value,
    }));
  };

  if (!user) return null;

  return (
    <div className="min-h-screen bg-filliminal-dark flex items-center justify-center p-4">
      <div className="max-w-md w-full bg-filliminal-dark-lighter rounded-lg border border-filliminal-yellow/20 p-6">
        <h1 className="text-2xl font-bold text-filliminal-yellow mb-6">Complete Your Profile</h1>
        
        <form onSubmit={handleSubmit} className="space-y-6">
          <div className="space-y-2">
            <Label htmlFor="fullName">Full Name</Label>
            <Input
              id="fullName"
              name="fullName"
              type="text"
              required
              value={formData.fullName}
              onChange={handleChange}
              placeholder="Enter your full name"
            />
          </div>

          <div className="space-y-2">
            <Label htmlFor="phoneNumber">Phone Number</Label>
            <Input
              id="phoneNumber"
              name="phoneNumber"
              type="tel"
              required
              value={formData.phoneNumber}
              onChange={handleChange}
              placeholder="Enter your phone number"
            />
          </div>

          <div className="space-y-2">
            <Label htmlFor="address">Address</Label>
            <Input
              id="address"
              name="address"
              type="text"
              required
              value={formData.address}
              onChange={handleChange}
              placeholder="Enter your address"
            />
          </div>

          <Button
            type="submit"
            variant="primary"
            className="w-full"
            disabled={isSubmitting}
          >
            {isSubmitting ? 'Verifying...' : 'Complete Verification'}
          </Button>
        </form>
      </div>
    </div>
  );
}
