import React, { useEffect, useState, useCallback, lazy, Suspense } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { toast } from 'sonner';
import { Button } from '../components/ui/Button';
import { Input } from '../components/ui/Input';
import { useAuth } from '../lib/hooks/useAuth';
import { socialAuthService, SocialProvider } from '../lib/services/socialAuthService';

// Lazy load social buttons
const SocialButtons = lazy(() => Promise.resolve({
  default: ({ onSocialLogin, isLoading }: { onSocialLogin: (provider: SocialProvider) => Promise<void>, isLoading: boolean }) => (
    <div className="mt-6">
      <div className="relative">
        <div className="absolute inset-0 flex items-center">
          <div className="w-full border-t border-filliminal-yellow/20" />
        </div>
        <div className="relative flex justify-center text-sm">
          <span className="px-2 bg-filliminal-dark text-filliminal-yellow/70">
            Or continue with
          </span>
        </div>
      </div>

      <div className="mt-6 grid grid-cols-2 gap-3">
        <Button
          type="button"
          variant="outline"
          onClick={() => onSocialLogin('google')}
          disabled={isLoading}
          className="w-full"
        >
          <img src="/google.svg" alt="Google" className="w-5 h-5 mr-2" />
          Google
        </Button>
        <Button
          type="button"
          variant="outline"
          onClick={() => onSocialLogin('facebook')}
          disabled={isLoading}
          className="w-full"
        >
          <img src="/facebook.svg" alt="Facebook" className="w-5 h-5 mr-2" />
          Facebook
        </Button>
      </div>
    </div>
  )
}));

export function Login() {
  const { user } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const redirectTo = location.state?.redirectTo || '/dashboard';

  // Memoize navigation check
  const checkNavigation = useCallback(() => {
    if (user && !user.needsVerification) {
      navigate(redirectTo, { replace: true });
    } else if (user?.needsVerification) {
      navigate('/verification', { replace: true });
    }
  }, [user, navigate, redirectTo]);

  useEffect(() => {
    checkNavigation();
  }, [checkNavigation]);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  // Memoize submit handler
  const onSubmit = useCallback(async (data: { email: string; password: string }) => {
    setIsLoading(true);
    try {
      await socialAuthService.signIn('email', data);
    } catch (error: any) {
      let errorMessage = 'Failed to sign in. Please check your credentials.';
      
      if (error.code === 'auth/invalid-credential') {
        errorMessage = 'Invalid email or password. Please try again.';
      } else if (error.code === 'auth/too-many-requests') {
        errorMessage = 'Too many failed attempts. Please try again later.';
      }
      
      toast.error(errorMessage);
      console.error('Sign in error:', error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  // Memoize social login handler
  const handleSocialLogin = useCallback(async (provider: SocialProvider) => {
    setIsLoading(true);
    try {
      await socialAuthService.signIn(provider);
    } catch (error: any) {
      console.error(`${provider} sign in error:`, error);
      toast.error(`Failed to sign in with ${provider}`);
    } finally {
      setIsLoading(false);
    }
  }, []);

  return (
    <div className="min-h-screen bg-filliminal-dark flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full space-y-8">
        <div>
          <h2 className="mt-6 text-center text-3xl font-extrabold text-filliminal-yellow">
            Sign in to your account
          </h2>
          <p className="mt-2 text-center text-sm text-filliminal-yellow/70">
            Or{' '}
            <Link to="/signup" className="font-medium text-filliminal-yellow hover:text-filliminal-yellow/90">
              create a new account
            </Link>
          </p>
        </div>

        <form className="mt-8 space-y-6" onSubmit={handleSubmit(onSubmit)}>
          <div className="rounded-md shadow-sm -space-y-px">
            <div>
              <label htmlFor="email-address" className="sr-only">
                Email address
              </label>
              <Input
                id="email-address"
                type="email"
                autoComplete="email"
                required
                className="rounded-t-md"
                placeholder="Email address"
                {...register('email')}
                disabled={isLoading}
              />
            </div>
            <div>
              <label htmlFor="password" className="sr-only">
                Password
              </label>
              <Input
                id="password"
                type="password"
                autoComplete="current-password"
                required
                className="rounded-b-md"
                placeholder="Password"
                {...register('password')}
                disabled={isLoading}
              />
            </div>
          </div>

          <div className="flex items-center justify-between">
            <div className="text-sm">
              <Link
                to="/forgot-password"
                className="font-medium text-filliminal-yellow hover:text-filliminal-yellow/90"
              >
                Forgot your password?
              </Link>
            </div>
          </div>

          <div>
            <Button
              type="submit"
              className="w-full"
              disabled={isLoading}
            >
              {isLoading ? 'Signing in...' : 'Sign in'}
            </Button>
          </div>
        </form>

        <Suspense fallback={<div className="mt-6 text-center text-filliminal-yellow/70">Loading options...</div>}>
          <SocialButtons onSocialLogin={handleSocialLogin} isLoading={isLoading} />
        </Suspense>
      </div>
    </div>
  );
}