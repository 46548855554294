import React, { useCallback } from 'react';
import { useFormContext } from 'react-hook-form';
import { useDropzone } from 'react-dropzone';
import type { Venue, VenuePhoto } from '../../../../../types/venue';
import { Typography } from '../../../../../components/ui/Typography';
import { Button } from '../../../../../components/ui/Button';
import { Input } from '../../../../../components/ui/Input';
import { X, Upload } from 'lucide-react';

export function MediaUpload() {
  const { register, setValue, watch, formState: { errors } } = useFormContext<Venue>();
  const photos = watch('photos') || [];

  const onDrop = useCallback((acceptedFiles: File[]) => {
    // TODO: Implement actual file upload to storage
    const newPhotos: VenuePhoto[] = acceptedFiles.map((file, index) => ({
      id: `temp-${Date.now()}-${index}`,
      url: URL.createObjectURL(file),
      caption: '',
      order: photos.length + index,
      isCover: photos.length === 0 && index === 0, // First photo is cover by default
    }));

    setValue('photos', [...photos, ...newPhotos]);
  }, [photos, setValue]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      'image/*': ['.jpeg', '.jpg', '.png', '.webp']
    },
    multiple: true
  });

  const removePhoto = (photoId: string) => {
    setValue(
      'photos',
      photos.filter(p => p.id !== photoId)
    );
  };

  const setCoverPhoto = (photoId: string) => {
    setValue(
      'photos',
      photos.map(p => ({
        ...p,
        isCover: p.id === photoId
      }))
    );
  };

  const updatePhotoCaption = (photoId: string, caption: string) => {
    setValue(
      'photos',
      photos.map(p => p.id === photoId ? { ...p, caption } : p)
    );
  };

  return (
    <div className="space-y-6">
      <Typography variant="h3" className="text-filliminal-yellow mb-4">
        Photos & Videos
      </Typography>

      <div
        {...getRootProps()}
        className={`border-2 border-dashed rounded-lg p-8 text-center cursor-pointer transition-colors
          ${isDragActive 
            ? 'border-filliminal-yellow bg-filliminal-yellow/10' 
            : 'border-filliminal-yellow/20 hover:border-filliminal-yellow/50'
          }`}
      >
        <input {...getInputProps()} />
        <Upload className="mx-auto h-12 w-12 text-filliminal-yellow/50 mb-4" />
        <Typography variant="body" className="text-filliminal-yellow mb-2">
          {isDragActive
            ? 'Drop the files here...'
            : 'Drag & drop photos here, or click to select files'}
        </Typography>
        <Typography variant="caption" className="text-filliminal-yellow/70">
          Supported formats: JPEG, PNG, WebP
        </Typography>
      </div>

      {photos.length > 0 && (
        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 mt-8">
          {photos.map((photo, index) => (
            <div key={photo.id} className="relative group">
              <img
                src={photo.url}
                alt={photo.caption || `Venue photo ${index + 1}`}
                className="w-full h-48 object-cover rounded-lg"
              />
              
              <div className="absolute inset-0 bg-black/50 opacity-0 group-hover:opacity-100 transition-opacity rounded-lg">
                <div className="absolute top-2 right-2 flex gap-2">
                  <button
                    type="button"
                    onClick={() => removePhoto(photo.id)}
                    className="p-1 bg-red-500 rounded-full hover:bg-red-600 transition-colors"
                  >
                    <X className="w-4 h-4" />
                  </button>
                </div>

                <div className="absolute bottom-2 left-2 right-2">
                  <Input
                    value={photo.caption}
                    onChange={(e) => updatePhotoCaption(photo.id, e.target.value)}
                    placeholder="Add caption..."
                    className="text-sm"
                  />
                </div>

                {!photo.isCover && (
                  <Button
                    type="button"
                    variant="secondary"
                    size="sm"
                    onClick={() => setCoverPhoto(photo.id)}
                    className="absolute top-2 left-2"
                  >
                    Set as Cover
                  </Button>
                )}
              </div>

              {photo.isCover && (
                <div className="absolute top-2 left-2 bg-filliminal-yellow text-filliminal-dark px-2 py-1 rounded text-xs">
                  Cover Photo
                </div>
              )}
            </div>
          ))}
        </div>
      )}

      <div className="mt-8">
        <Typography variant="h4" className="text-filliminal-yellow mb-4">
          Virtual Tour
        </Typography>
        <Input
          {...register('virtualTourUrl')}
          placeholder="Enter virtual tour URL (optional)"
        />
        <Typography variant="caption" className="text-filliminal-yellow/70 mt-2">
          Support for Matterport, YouTube, or other virtual tour platforms
        </Typography>
      </div>
    </div>
  );
}
