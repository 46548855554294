export function PrivacyPolicy() {
  return (
    <div className="container mx-auto px-4 py-8 max-w-4xl">
      <h1 className="text-3xl font-bold mb-6 text-filliminal-yellow">Privacy Policy</h1>
      
      <div className="space-y-6 text-filliminal-light">
        <section>
          <h2 className="text-2xl font-semibold mb-3 text-filliminal-yellow">Last Updated: December 7, 2023</h2>
          <p>This privacy policy describes how Filliminal ("we", "our", or "us") collects, uses, and protects your personal information.</p>
        </section>

        <section>
          <h2 className="text-xl font-semibold mb-3 text-filliminal-yellow">Information We Collect</h2>
          <ul className="list-disc pl-6 space-y-2">
            <li>Account information (email, name, profile picture)</li>
            <li>Authentication data from social login providers</li>
            <li>Usage data and analytics</li>
            <li>Device and browser information</li>
          </ul>
        </section>

        <section>
          <h2 className="text-xl font-semibold mb-3 text-filliminal-yellow">How We Use Your Information</h2>
          <ul className="list-disc pl-6 space-y-2">
            <li>To provide and improve our services</li>
            <li>To authenticate your identity</li>
            <li>To communicate with you about our services</li>
            <li>To ensure platform security</li>
          </ul>
        </section>

        <section>
          <h2 className="text-xl font-semibold mb-3 text-filliminal-yellow">Data Protection</h2>
          <p>We implement appropriate technical and organizational measures to protect your personal information.</p>
        </section>

        <section>
          <h2 className="text-xl font-semibold mb-3 text-filliminal-yellow">Your Rights</h2>
          <p>You have the right to:</p>
          <ul className="list-disc pl-6 space-y-2">
            <li>Access your personal data</li>
            <li>Request correction of your data</li>
            <li>Request deletion of your data</li>
            <li>Withdraw consent at any time</li>
          </ul>
        </section>

        <section>
          <h2 className="text-xl font-semibold mb-3 text-filliminal-yellow">Contact Us</h2>
          <p>For any privacy-related questions or concerns, please contact us at privacy@filliminal.com</p>
        </section>
      </div>
    </div>
  );
}
