import React, { useState } from 'react';
import { SearchHeader } from '../components/search/SearchHeader';
import { FilterPanel } from '../components/search/FilterPanel';
import { ResultsGrid } from '../components/search/ResultsGrid';
import { mockVenues } from '../lib/mocks/venues';

export function Search() {
  const [viewMode, setViewMode] = useState<'grid' | 'list'>('grid');

  return (
    <div className="min-h-screen bg-filliminal-dark">
      <SearchHeader />
      
      <div className="max-w-7xl mx-auto px-4 py-8">
        <div className="flex gap-8">
          <aside className="w-80 flex-shrink-0">
            <div className="sticky top-24 z-30">
              <FilterPanel />
            </div>
          </aside>
          
          <main className="flex-1">
            <ResultsGrid
              venues={mockVenues}
              viewMode={viewMode}
              onViewModeChange={setViewMode}
            />
          </main>
        </div>
      </div>
    </div>
  );
}