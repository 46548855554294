import React from 'react';
import { cn } from '../../lib/utils';

interface TypographyProps extends React.HTMLAttributes<HTMLElement> {
  variant?: 'h1' | 'h2' | 'h3' | 'h4' | 'body' | 'small';
  component?: keyof JSX.IntrinsicElements;
}

export function Typography({
  variant = 'body',
  component,
  className,
  children,
  ...props
}: TypographyProps) {
  const variants = {
    h1: 'text-4xl md:text-5xl font-bold text-filliminal-yellow',
    h2: 'text-3xl md:text-4xl font-bold text-filliminal-yellow',
    h3: 'text-2xl md:text-3xl font-semibold text-filliminal-yellow',
    h4: 'text-xl md:text-2xl font-semibold text-filliminal-yellow',
    body: 'text-base text-filliminal-yellow/90',
    small: 'text-sm text-filliminal-yellow/70',
  };

  const Component = component || {
    h1: 'h1',
    h2: 'h2',
    h3: 'h3',
    h4: 'h4',
    body: 'p',
    small: 'span',
  }[variant] as keyof JSX.IntrinsicElements;

  return React.createElement(
    Component,
    {
      className: cn(variants[variant], className),
      ...props,
    },
    children
  );
}