import React from 'react';
import { LocationSearch } from '../search/LocationSearch';
import { Typography } from '../ui/Typography';

export function HeroSection() {
  const handleLocationSelect = (location: { address: string; lat: number; lng: number }) => {
    console.log('Selected location:', location);
  };

  return (
    <section className="relative text-center space-y-8 py-20 px-4 rounded-3xl overflow-hidden border border-filliminal-yellow/20">
      <div className="absolute inset-0 bg-[url('https://images.unsplash.com/photo-1497366216548-37526070297c')] bg-cover bg-center opacity-10" />
      <div className="relative z-10 max-w-4xl mx-auto">
        <Typography variant="h1" className="text-filliminal-yellow text-5xl md:text-6xl mb-6">
          Between Hours, Beyond Ordinary
        </Typography>
        <Typography variant="body" className="text-2xl text-filliminal-yellow font-medium mb-8 opacity-90">
          Transform empty spaces into extraordinary opportunities during off-peak hours.
          Perfect for events, meetings, or pop-up experiences.
        </Typography>
        <div className="max-w-2xl mx-auto">
          <LocationSearch onLocationSelect={handleLocationSelect} />
        </div>
      </div>
    </section>
  );
}