import React from 'react';
import { LocationSearch } from './LocationSearch';
import { DatePicker } from './DatePicker';

export function SearchHeader() {
  return (
    <div className="bg-filliminal-dark border-b border-filliminal-yellow/20 sticky top-0 z-40">
      <div className="max-w-7xl mx-auto px-4 py-4">
        <div className="grid md:grid-cols-2 gap-4">
          <LocationSearch onLocationSelect={() => {}} />
          <DatePicker />
        </div>
      </div>
    </div>
  );
}