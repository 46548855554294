import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import App from './App.tsx';
import './index.css';

const rootElement = document.getElementById('root');
console.log('Root element:', rootElement);

if (!rootElement) {
  throw new Error('Failed to find the root element');
}

const root = createRoot(rootElement);
console.log('Created React root');

try {
  root.render(
    <StrictMode>
      <App />
    </StrictMode>
  );
  console.log('Rendered App component');
} catch (error) {
  console.error('Failed to render app:', error);
}
