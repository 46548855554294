import React from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Mail } from 'lucide-react';
import { Typography } from '../../components/ui/Typography';
import { Button } from '../../components/ui/Button';
import { emailSchema } from '../../lib/validations/auth';

interface EmailVerificationProps {
  onEmailSubmit: (email: string) => void;
  isLoading: boolean;
}

export function EmailVerification({ onEmailSubmit, isLoading }: EmailVerificationProps) {
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<{ email: string }>({
    resolver: zodResolver(emailSchema)
  });

  return (
    <form onSubmit={handleSubmit(({ email }) => onEmailSubmit(email))} className="space-y-6">
      <div>
        <div className="relative">
          <Mail className="absolute left-4 top-4 h-5 w-5 text-filliminal-yellow/50" />
          <input
            {...register('email')}
            type="email"
            placeholder="Email address"
            className="w-full h-14 px-12 bg-white/5 text-filliminal-yellow border border-filliminal-yellow/20 rounded-lg focus:outline-none focus:border-filliminal-yellow focus:ring-1 focus:ring-filliminal-yellow placeholder-filliminal-yellow/50"
          />
        </div>
        {errors.email && (
          <div className="mt-2 text-red-500 text-sm">
            {errors.email.message}
          </div>
        )}
      </div>

      <Button
        type="submit"
        variant="primary"
        className="w-full h-14 bg-filliminal-yellow text-filliminal-dark hover:bg-opacity-90 text-lg font-medium"
        disabled={isLoading}
      >
        Create Account
      </Button>
    </form>
  );
}