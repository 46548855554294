import React from 'react';

export function FilterPanel() {
  return (
    <div className="bg-filliminal-dark-lighter rounded-lg p-4 border border-filliminal-yellow/20">
      <h2 className="text-filliminal-yellow text-lg font-semibold mb-4">Filters</h2>
      
      {/* Price Range */}
      <div className="mb-6">
        <h3 className="text-filliminal-yellow/80 text-sm font-medium mb-2">Price Range</h3>
        <div className="space-y-2">
          <label className="flex items-center">
            <input type="checkbox" className="form-checkbox text-filliminal-yellow" />
            <span className="ml-2 text-filliminal-yellow/60">$0 - $100</span>
          </label>
          <label className="flex items-center">
            <input type="checkbox" className="form-checkbox text-filliminal-yellow" />
            <span className="ml-2 text-filliminal-yellow/60">$101 - $200</span>
          </label>
          <label className="flex items-center">
            <input type="checkbox" className="form-checkbox text-filliminal-yellow" />
            <span className="ml-2 text-filliminal-yellow/60">$201 - $500</span>
          </label>
          <label className="flex items-center">
            <input type="checkbox" className="form-checkbox text-filliminal-yellow" />
            <span className="ml-2 text-filliminal-yellow/60">$500+</span>
          </label>
        </div>
      </div>
      
      {/* Venue Type */}
      <div className="mb-6">
        <h3 className="text-filliminal-yellow/80 text-sm font-medium mb-2">Venue Type</h3>
        <div className="space-y-2">
          <label className="flex items-center">
            <input type="checkbox" className="form-checkbox text-filliminal-yellow" />
            <span className="ml-2 text-filliminal-yellow/60">Gallery</span>
          </label>
          <label className="flex items-center">
            <input type="checkbox" className="form-checkbox text-filliminal-yellow" />
            <span className="ml-2 text-filliminal-yellow/60">Retail Space</span>
          </label>
          <label className="flex items-center">
            <input type="checkbox" className="form-checkbox text-filliminal-yellow" />
            <span className="ml-2 text-filliminal-yellow/60">Event Space</span>
          </label>
          <label className="flex items-center">
            <input type="checkbox" className="form-checkbox text-filliminal-yellow" />
            <span className="ml-2 text-filliminal-yellow/60">Studio</span>
          </label>
        </div>
      </div>
      
      {/* Amenities */}
      <div>
        <h3 className="text-filliminal-yellow/80 text-sm font-medium mb-2">Amenities</h3>
        <div className="space-y-2">
          <label className="flex items-center">
            <input type="checkbox" className="form-checkbox text-filliminal-yellow" />
            <span className="ml-2 text-filliminal-yellow/60">WiFi</span>
          </label>
          <label className="flex items-center">
            <input type="checkbox" className="form-checkbox text-filliminal-yellow" />
            <span className="ml-2 text-filliminal-yellow/60">Parking</span>
          </label>
          <label className="flex items-center">
            <input type="checkbox" className="form-checkbox text-filliminal-yellow" />
            <span className="ml-2 text-filliminal-yellow/60">Sound System</span>
          </label>
          <label className="flex items-center">
            <input type="checkbox" className="form-checkbox text-filliminal-yellow" />
            <span className="ml-2 text-filliminal-yellow/60">Lighting</span>
          </label>
        </div>
      </div>
    </div>
  );
}