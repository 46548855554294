import React from 'react';
import { Plus, Search } from 'lucide-react';
import { Typography } from '../../../components/ui/Typography';
import { Button } from '../../../components/ui/Button';
import { Input } from '../../../components/ui/Input';
import { useNavigate } from 'react-router-dom';

export function VenueManagement() {
  const navigate = useNavigate();
  const mockVenues = [
    {
      id: '1',
      name: 'The Grand Hall',
      location: 'Downtown, City',
      capacity: 500,
      pricePerHour: 250,
      rating: 4.8,
      imageUrl: '/images/venues/grand-hall.jpg'
    },
    {
      id: '2',
      name: 'Rooftop Lounge',
      location: 'Midtown, City',
      capacity: 150,
      pricePerHour: 175,
      rating: 4.5,
      imageUrl: '/images/venues/rooftop-lounge.jpg'
    },
    {
      id: '3',
      name: 'Garden Pavilion',
      location: 'Westside, City',
      capacity: 300,
      pricePerHour: 200,
      rating: 4.7,
      imageUrl: '/images/venues/garden-pavilion.jpg'
    }
  ];

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <Typography variant="h2" className="text-filliminal-yellow">Venue Management</Typography>
        <Button 
          variant="primary"
          onClick={() => navigate('/dashboard/venues/new')}
          className="flex items-center gap-2"
        >
          <Plus className="w-4 h-4" />
          Add New Venue
        </Button>
      </div>

      <div className="flex gap-4 items-center">
        <div className="relative flex-1">
          <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-filliminal-yellow/50 w-5 h-5" />
          <Input
            type="text"
            placeholder="Search venues..."
            className="pl-10"
          />
        </div>
        <Button variant="secondary">
          Filter
        </Button>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {mockVenues.map((venue) => (
          <div 
            key={venue.id}
            className="bg-filliminal-dark-lighter rounded-lg overflow-hidden border border-filliminal-yellow/20 hover:border-filliminal-yellow/40 transition-colors"
          >
            <div className="aspect-video bg-filliminal-dark-lighter relative">
              <img 
                src={venue.imageUrl} 
                alt={venue.name}
                className="w-full h-full object-cover"
              />
            </div>
            <div className="p-4 space-y-2">
              <Typography variant="h4" className="text-filliminal-yellow">{venue.name}</Typography>
              <div className="flex justify-between text-filliminal-yellow/70">
                <span>{venue.location}</span>
                <span>⭐ {venue.rating}</span>
              </div>
              <div className="flex justify-between text-filliminal-yellow/70">
                <span>Capacity: {venue.capacity}</span>
                <span>${venue.pricePerHour}/hr</span>
              </div>
              <div className="flex gap-2 mt-4">
                <Button variant="secondary" className="flex-1">Edit</Button>
                <Button variant="secondary" className="flex-1">View</Button>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}