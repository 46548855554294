import { useCallback, useState } from 'react';
import { 
  signInWithEmailAndPassword, 
  createUserWithEmailAndPassword,
  GoogleAuthProvider,
  FacebookAuthProvider,
  signInWithPopup,
  signInWithCredential,
  signOut as firebaseSignOut
} from 'firebase/auth';
import { auth } from '../lib/firebase';
import { useNavigate, useLocation } from 'react-router-dom';
import toast from 'react-hot-toast';
import type { SignInData, FacebookAuthStatus, FacebookStatusResponse } from '../types/auth';
import { useAuthContext } from '../providers/AuthProvider';

declare global {
  interface Window {
    FB: any;
  }
}

export function useAuth() {
  const navigate = useNavigate();
  const location = useLocation();
  const { user } = useAuthContext();
  const [facebookAuthStatus, setFacebookAuthStatus] = useState<FacebookAuthStatus>({
    status: 'unknown',
    authResponse: null,
    isAuthenticated: false
  });

  const signInWithEmail = useCallback(async ({ email, password }: SignInData) => {
    try {
      await signInWithEmailAndPassword(auth, email, password);
      toast.success('Signed in successfully!');
      const redirectTo = location.state?.redirectTo || '/dashboard';
      navigate(redirectTo, { replace: true });
    } catch (error: any) {
      console.error('Sign in failed:', error);
      if (error.code === 'auth/user-not-found') {
        throw new Error('user-not-found');
      } else if (error.code === 'auth/wrong-password') {
        throw new Error('wrong-password');
      } else {
        throw new Error('general');
      }
    }
  }, [navigate, location]);

  const signUpWithEmail = useCallback(async ({ email, password }: SignInData) => {
    try {
      await createUserWithEmailAndPassword(auth, email, password);
      toast.success('Account created successfully!');
      navigate('/dashboard');
    } catch (error: any) {
      console.error('Sign up failed:', error);
      if (error.code === 'auth/email-already-in-use') {
        throw new Error('email-already-in-use');
      }
      throw new Error('general');
    }
  }, [navigate]);

  const signInWithGoogle = useCallback(async () => {
    try {
      const provider = new GoogleAuthProvider();
      const result = await signInWithPopup(auth, provider);
      
      // Only show success message and redirect if we have a result
      if (result.user) {
        toast.success('Signed in with Google successfully!');
        const redirectTo = location.state?.redirectTo || '/dashboard';
        // Use replace instead of push to avoid browser history stacking
        navigate(redirectTo, { replace: true });
      }
      return result;
    } catch (error) {
      console.error('Google sign in failed:', error);
      toast.error('Failed to sign in with Google');
      throw new Error('general');
    }
  }, [navigate, location]);

  const signInWithFacebook = useCallback(async () => {
    if (!window.FB) {
      console.error('Facebook SDK not loaded');
      throw new Error('facebook-sdk-not-loaded');
    }

    try {
      // First, get Facebook login status
      const fbResponse: FacebookStatusResponse = await new Promise((resolve, reject) => {
        window.FB.login((response: FacebookStatusResponse) => {
          if (response.status === 'connected' && response.authResponse) {
            resolve(response);
          } else {
            reject(new Error('Facebook login failed or was cancelled'));
          }
        }, { scope: 'email,public_profile' });
      });

      // Then sign in with Firebase using the Facebook credential
      const credential = FacebookAuthProvider.credential(
        fbResponse.authResponse.accessToken
      );

      const result = await signInWithCredential(auth, credential);
      toast.success('Signed in with Facebook successfully!');
      
      // Update Facebook auth status
      setFacebookAuthStatus({
        status: fbResponse.status,
        authResponse: fbResponse.authResponse,
        isAuthenticated: true
      });

      const redirectTo = location.state?.redirectTo || '/dashboard';
      navigate(redirectTo, { replace: true });
      return result;
    } catch (error) {
      console.error('Facebook sign in failed:', error);
      if (error.message === 'facebook-sdk-not-loaded') {
        toast.error('Facebook login is not available right now. Please try again later.');
      } else if (error.message === 'Facebook login failed or was cancelled') {
        toast.error('Facebook login was cancelled or failed. Please try again.');
      }
      throw error;
    }
  }, [navigate, location]);

  const signOut = useCallback(async () => {
    try {
      await firebaseSignOut(auth);
      // Also log out from Facebook if connected
      if (window.FB && facebookAuthStatus.status === 'connected') {
        window.FB.logout(() => {
          setFacebookAuthStatus({
            status: 'unknown',
            authResponse: null,
            isAuthenticated: false
          });
        });
      }
      toast.success('Signed out successfully');
      navigate('/');
    } catch (error) {
      console.error('Sign out failed:', error);
      toast.error('Failed to sign out');
    }
  }, [navigate, facebookAuthStatus.status]);

  return {
    user,
    signInWithEmail,
    signUpWithEmail,
    signInWithGoogle,
    signInWithFacebook,
    signOut,
    facebookAuthStatus,
    setFacebookAuthStatus
  };
}