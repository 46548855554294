import React from 'react';
import { DollarSign, TrendingUp, Star, Clock } from 'lucide-react';
import { Typography } from '../../../../components/ui/Typography';
import { Card } from '../../../../components/ui/Card';

export function PerformanceMetrics() {
  const metrics = [
    {
      label: 'Total Revenue',
      value: '$12,450',
      change: '+12%',
      icon: DollarSign,
    },
    {
      label: 'Booking Rate',
      value: '85%',
      change: '+5%',
      icon: TrendingUp,
    },
    {
      label: 'Average Rating',
      value: '4.8',
      change: '+0.2',
      icon: Star,
    },
    {
      label: 'Response Time',
      value: '2h',
      change: '-15m',
      icon: Clock,
    },
  ];

  return (
    <div className="space-y-6">
      <Typography variant="h2" className="text-filliminal-yellow">Performance Metrics</Typography>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
        {metrics.map((metric) => (
          <Card key={metric.label} className="bg-filliminal-dark-lighter border border-filliminal-yellow/20">
            <Card.Body>
              <div className="flex items-start justify-between">
                <div>
                  <Typography variant="body" className="text-filliminal-yellow/70 mb-1">
                    {metric.label}
                  </Typography>
                  <Typography variant="h3" className="text-filliminal-yellow">
                    {metric.value}
                  </Typography>
                </div>
                <div className="p-2 bg-filliminal-yellow/10 rounded-lg">
                  <metric.icon className="w-6 h-6 text-filliminal-yellow" />
                </div>
              </div>
              <div className="mt-4 flex items-center text-sm">
                <TrendingUp className="w-4 h-4 text-green-500 mr-1" />
                <span className="text-green-500">{metric.change}</span>
                <span className="text-filliminal-yellow/50 ml-1">vs last month</span>
              </div>
            </Card.Body>
          </Card>
        ))}
      </div>

      {/* Add charts and detailed statistics here */}
    </div>
  );
}