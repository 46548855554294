import React from 'react';
import { Outlet } from 'react-router-dom';
import { Sidebar } from './Sidebar';
import { Header } from '../../../components/layout/Header';
import { useAuth } from '../../../lib/hooks/useAuth';

interface DashboardLayoutProps {
  isStale?: boolean;
  onRefresh?: () => Promise<void>;
}

export function DashboardLayout({ isStale, onRefresh }: DashboardLayoutProps) {
  const { user } = useAuth();

  return (
    <div className="min-h-screen bg-filliminal-dark flex">
      <Sidebar user={user} />
      
      <div className="flex-1 flex flex-col">
        <Header 
          user={user} 
          isStale={isStale}
          onRefresh={onRefresh}
        />
        
        <main className="flex-1 p-6 overflow-auto">
          {isStale && (
            <div className="mb-4 p-3 bg-filliminal-yellow/10 text-filliminal-yellow rounded-lg flex items-center justify-between">
              <span>
                Some data may be outdated. Click refresh to get the latest updates.
              </span>
              <button
                onClick={onRefresh}
                className="text-filliminal-yellow hover:text-filliminal-yellow/80 font-medium"
              >
                Refresh
              </button>
            </div>
          )}
          
          <Outlet />
        </main>
      </div>
    </div>
  );
}
