import React from 'react';
import { cn } from '../../lib/utils';

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  error?: string;
}

export const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, label, error, ...props }, ref) => {
    return (
      <div className="w-full">
        {label && (
          <label className="block text-sm font-medium text-filliminal-yellow mb-1">
            {label}
          </label>
        )}
        <input
          className={cn(
            'w-full px-4 py-2 bg-filliminal-dark border rounded-lg',
            'focus:outline-none focus:ring-2 focus:ring-filliminal-yellow/50',
            error ? 'border-red-500' : 'border-filliminal-yellow/20',
            'text-filliminal-yellow placeholder-filliminal-yellow/50',
            'transition-colors duration-200',
            className
          )}
          ref={ref}
          {...props}
        />
        {error && (
          <p className="mt-1 text-sm text-red-500">{error}</p>
        )}
      </div>
    );
  }
);

Input.displayName = 'Input';
