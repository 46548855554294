import { 
  initializeApp, 
  getApps,
} from 'firebase/app';
import { 
  getFirestore, 
  initializeFirestore,
  enableNetwork as enableFirestoreNetwork,
  disableNetwork as disableFirestoreNetwork,
  type FirestoreSettings,
} from 'firebase/firestore';
import { getAuth, connectAuthEmulator } from 'firebase/auth';
import { getStorage } from 'firebase/storage';

const firebaseConfig = {
  apiKey: import.meta.env.VITE_FIREBASE_API_KEY,
  authDomain: import.meta.env.VITE_FIREBASE_AUTH_DOMAIN,
  projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID,
  storageBucket: import.meta.env.VITE_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: import.meta.env.VITE_FIREBASE_MESSAGING_SENDER_ID,
  appId: import.meta.env.VITE_FIREBASE_APP_ID,
};

// Initialize Firebase app
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const storage = getStorage(app);

// Configure Firestore settings for better performance and reliability
const settings: FirestoreSettings = {
  cache: {
    // Use local persistence with a 50MB cache size
    cacheSizeBytes: 50 * 1024 * 1024,
  },
  ignoreUndefinedProperties: true,
  experimentalAutoDetectLongPolling: true,
};

// Initialize Firestore with settings
const db = initializeFirestore(app, settings);

// Network state management functions with timeouts and retries
let networkTimeout: NodeJS.Timeout;
const NETWORK_TIMEOUT = 10000; // 10 seconds

export async function enableNetwork() {
  clearTimeout(networkTimeout);
  networkTimeout = setTimeout(async () => {
    try {
      await enableFirestoreNetwork(db);
      console.log('Firestore network enabled');
    } catch (error) {
      console.error('Error enabling network:', error);
    }
  }, 1000); // Debounce network changes
}

export async function disableNetwork() {
  clearTimeout(networkTimeout);
  networkTimeout = setTimeout(async () => {
    try {
      await disableFirestoreNetwork(db);
      console.log('Firestore network disabled');
    } catch (error) {
      console.error('Error disabling network:', error);
    }
  }, 1000);
}

// Export configured instances
export { db, auth, storage };

// Enable Auth emulator in development
if (import.meta.env.DEV) {
  connectAuthEmulator(auth, 'http://localhost:9099');
}

// Export initialized app
export { app };

// Photo upload helpers
export async function uploadVenuePhoto(venueId: string, file: File, options?: {
  maxWidth?: number;
  maxHeight?: number;
  quality?: number;
}): Promise<VenuePhoto> {
  // Default optimization options
  const { maxWidth = 1920, maxHeight = 1080, quality = 0.8 } = options || {};

  // Optimize image before upload
  const optimizedFile = await optimizeImage(file, {
    maxWidth,
    maxHeight,
    quality,
    fileType: 'image/jpeg' // Convert all images to JPEG for consistency
  });

  const photoId = window.crypto.randomUUID();
  const photoRef = ref(storage, `venues/${venueId}/photos/${photoId}.jpg`);
  
  await uploadBytes(photoRef, optimizedFile);
  const url = await getDownloadURL(photoRef);
  
  return {
    id: photoId,
    url,
    fileExtension: 'jpg'
  };
}

async function optimizeImage(
  file: File,
  options: {
    maxWidth: number;
    maxHeight: number;
    quality: number;
    fileType: string;
  }
): Promise<Blob> {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => {
      const canvas = document.createElement('canvas');
      let { width, height } = img;

      // Calculate new dimensions while maintaining aspect ratio
      if (width > options.maxWidth) {
        height = (height * options.maxWidth) / width;
        width = options.maxWidth;
      }
      if (height > options.maxHeight) {
        width = (width * options.maxHeight) / height;
        height = options.maxHeight;
      }

      canvas.width = width;
      canvas.height = height;

      const ctx = canvas.getContext('2d');
      if (!ctx) {
        reject(new Error('Failed to get canvas context'));
        return;
      }

      // Draw and optimize image
      ctx.drawImage(img, 0, 0, width, height);
      canvas.toBlob(
        (blob) => {
          if (blob) {
            resolve(blob);
          } else {
            reject(new Error('Failed to create blob'));
          }
        },
        options.fileType,
        options.quality
      );
    };

    img.onerror = () => {
      reject(new Error('Failed to load image'));
    };

    img.src = URL.createObjectURL(file);
  });
}

// Batch upload photos
export async function uploadVenuePhotos(
  venueId: string,
  files: File[],
  onProgress?: (progress: number) => void
): Promise<VenuePhoto[]> {
  const total = files.length;
  const photos: VenuePhoto[] = [];

  for (let i = 0; i < total; i++) {
    const photo = await uploadVenuePhoto(venueId, files[i], {
      maxWidth: i === 0 ? 1920 : 1280, // Higher quality for cover photo
      maxHeight: i === 0 ? 1080 : 720,
      quality: i === 0 ? 0.9 : 0.8
    });
    photos.push(photo);

    if (onProgress) {
      onProgress((i + 1) / total);
    }
  }

  return photos;
}

export async function deleteVenuePhotos(venueId: string, photoIds: string[]) {
  await Promise.all(
    photoIds.map(photoId => 
      deleteObject(ref(storage, `venues/${venueId}/photos/${photoId}.jpg`))
    )
  );
}